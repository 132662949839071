import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getChallengesStatusSuccess,
  getChallengesStatusFailure,
} from './actions';
import { changeWhatsappbotFunctionsRequest } from '../whatsappBot/actions';

const userData = (state) => state.user.user;

export function* getCurrentChallenge() {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/challenges`
    );

    yield put(getChallengesStatusSuccess(response.data));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      toast.error(err.response.data.error.message, {
        autoClose: 6000,
      });
    } else {
      toast.error('Ocorreu um erro ao buscar os dados de desafio do cliente.', {
        autoClose: 6000,
      });
    }
    yield put(getChallengesStatusFailure());
  }
}

export function* challengeCompletedModalClosedRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/challenges/${payload.challengeId}/update-completed-modal`,
      {
        is_completed_modal_closed: payload.isCompletedModalClosed
      }
    );
    yield put(getChallengesStatusSuccess(response.data));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      toast.error(err.response.data.error.message, {
        autoClose: 6000,
      });
    }
  }
}

export function* sendTestMessage() {
  try {
    const user = yield select(userData);

    yield put(changeWhatsappbotFunctionsRequest(true, true))

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/challenges/send-test-message`,
      {
        name: user.advertiserInfo.name,
        phone:user.advertiserInfo.advertiserWhatsappbotConfig.phone
      }
    );

    toast.success('Mensagem de teste enviada com sucesso!')
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      toast.error(err.response.data.error.message, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest(
    '@challenges/GET_CHALLENGES_STATUS_REQUEST',
    getCurrentChallenge
  ),
  takeLatest(
    '@challenges/CHALLENGE_COMPLETED_MODAL_CLOSED_REQUEST',
    challengeCompletedModalClosedRequest
  ),
  takeLatest('@challenges/SEND_TEST_MESSAGE', sendTestMessage)
]);
