import produce from 'immer';

import { paginateState } from '../../../utils/commonObjects';
import { formatArrayAsObject, getOrderStateNameByStatus } from '../../../utils/utils';

const INITIAL_STATE = {
  currSearchedOrderData: null,
  currOrderStatusData: {},
  currOrderConfigs: {
    printOrder: false,
    sendWhatsappMessage: false,
  },
  ordersQty: {
    totalNewAcceptedOrdersQty: "0",
    totalPreparingOrdersQty: "0",
    totalReadyTakeAwayOrdersQty: "0"
  },
  newAccepted: {},
  newAcceptedPagination: paginateState,
  preparing: {},
  preparingPagination: paginateState,
  deliveringReadyTakeAway: {},
  deliveringReadyTakeAwayPagination: paginateState,
  finished: {},
  finishedPagination: paginateState,
  error: false,
  getSearchedOrderLoading: false,
  getOrdersLoading: false,
  getOrdersSuccess: false,
  getOrdersPaginatedLoading: false,
  getOrdersPaginatedSuccess: false,
  getOrderDetailLoading: false,
  updateOrderStatusLoading: false,
  updateOrderStatusSuccess: false,
  cancelOrderLoading: false,
  cancelOrderSuccess: false,
  changeOrdersConfigsLoading: false,
  changeOrdersConfigsSuccess: false,
  pixKeyRequestLoading: false,
  pixKeyRequestSuccess: false,
  updatePaymentMethodLoading: false,
  updatePaymentMethodFailure: false,
  newOrderPaymentMethod: null,
  isQzConnected: false,
  createTestOrderLoading: false,
  createTestOrderSuccess: false,
  listToMassActionLoading: false,
  listToMassActionSuccess: false,
  listToMassActionFailure: false,
  idsListToMassAction: [],
};

export default function orders(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@orders/GET_ORDERS_QTY_SUCCESS': {
        draft.ordersQty = {
          totalNewAcceptedOrdersQty: "0",
          totalPreparingOrdersQty: "0",
          totalReadyTakeAwayOrdersQty: "0"
        };
        draft.ordersQty = {
          totalNewAcceptedOrdersQty: String(parseInt(action.payload.ordersQty.total_new_orders_qty) + parseInt(action.payload.ordersQty.total_accepted_orders_qty)),
          totalPreparingOrdersQty: action.payload.ordersQty.total_preparing_orders_qty,
          totalReadyTakeAwayOrdersQty: action.payload.ordersQty.total_ready_take_away_orders_qty,
        };
        break;
      }
      case '@orders/GET_ORDERS_REQUEST': {
        draft.error = false;
        draft.getOrdersLoading = true;
        draft.getOrdersSuccess = false;
        break;
      }
      case '@orders/GET_ORDERS_SUCCESS': {
        draft.error = false;
        draft.getOrdersLoading = false;
        const orderState = getOrderStateNameByStatus(action.payload.status);
        draft[orderState] = {}
        draft[orderState] = formatArrayAsObject(action.payload.orders, 'order_id');
        if (action.payload.status !== 'new') {
          draft[`${orderState}Pagination`] = paginateState;
          draft[`${orderState}Pagination`] = action.payload.ordersPagination;
        }
        draft.getOrdersSuccess = true;
        break;
      }
      case '@orders/GET_ORDERS_FAILURE': {
        draft.error = true;
        draft.getOrdersLoading = false;
        draft.ordersQty = {
          totalNewAcceptedOrdersQty: "0",
          totalPreparingOrdersQty: "0",
          totalReadyTakeAwayOrdersQty: "0"
        };
        if (action.payload.status === 'new_accepted') {
          draft.newAccepted = {};
          draft.newAcceptedPagination = paginateState;
        } else if (action.payload.status === 'preparing') {
          draft.preparing = {};
          draft.preparingPagination = paginateState;
        } else if (action.payload.status === 'deliveringReadyTakeAway') {
          draft.deliveringReadyTakeAway = {};
          draft.deliveringReadyTakeAwayPagination = paginateState;
        } else if (action.payload.status === 'finished') {
          draft.finished = {};
          draft.finishedPagination = paginateState;
        }
        draft.getOrdersSuccess = false;
        break;
      }
      case '@orders/GET_ORDERS_WITHOUT_LOADER_REQUEST' ||
        '@orders/GET_NEW_ACCEPTED_ORDERS_WITHOUT_LOADER_REQUEST': {
        draft.error = false;
        draft.getOrdersSuccess = false;
        break;
      }
      case '@orders/GET_ORDERS_WITHOUT_LOADER_SUCCESS': {
        draft.error = false;
        draft.newAccepted = {};
        draft.newAccepted = { ...state.newAccepted, ...formatArrayAsObject(action.payload.orders, 'order_id') };
        draft.newAcceptedPagination = paginateState;
        draft.newAcceptedPagination = action.payload.ordersPagination
        draft.getOrdersSuccess = true;
        break;
      }
      case '@orders/GET_ORDERS_WITHOUT_LOADER_FAILURE': {
        draft.error = true;
        draft.newAccepted = {};
        draft.newAcceptedPagination = paginateState;
        draft.getOrdersSuccess = false;
        break;
      }
      case '@orders/GET_ORDERS_PAGINATED_REQUEST': {
        draft.error = false;
        draft.getOrdersPaginatedLoading = true;
        draft.getOrdersPaginatedSuccess = false;
        break;
      }
      case '@orders/GET_ORDERS_PAGINATED_SUCCESS': {
        draft.error = false;
        draft.getOrdersPaginatedLoading = false;
        const orderState = getOrderStateNameByStatus(action.payload.status);
        draft[orderState] = {}
        draft[orderState] = formatArrayAsObject(action.payload.orders, 'order_id');
        if (action.payload.status !== 'new') {
          draft[`${orderState}Pagination`] = paginateState;
          draft[`${orderState}Pagination`] = action.payload.ordersPagination;
        }
        draft.getOrdersPaginatedSuccess = true;
        break;
      }
      case '@orders/GET_ORDER_DETAIL_REQUEST': {
        draft.getOrderDetailLoading = true;
        break;
      }
      case '@orders/GET_ORDER_DETAIL_SUCCESS': {
        draft.getOrderDetailLoading = false;
        const orderState = getOrderStateNameByStatus(action.payload.orderDetail.status);
        const newOrderState = { ...state[orderState] }
        newOrderState[action.payload.orderDetail.order_id] = action.payload.orderDetail;
        draft[orderState] = newOrderState;
        break;
      }
      case '@orders/GET_ORDER_DETAIL_FAILURE': {
        draft.getOrderDetailLoading = false;
        break;
      }
      case '@orders/UPDATE_ORDER_STATUS_REQUEST': {
        draft.updateOrderStatusLoading = true;
        draft.updateOrderStatusSuccess = false;
        break;
      }
      case '@orders/UPDATE_ORDER_STATUS_SUCCESS': {
        draft.updateOrderStatusLoading = false;
        draft.updateOrderStatusSuccess = true;
        break;
      }
      case '@orders/UPDATE_ORDER_STATUS_FAILURE': {
        draft.updateOrderStatusLoading = false;
        draft.updateOrderStatusSuccess = false;
        break;
      }
      case '@orders/CANCEL_ORDER_REQUEST': {
        draft.cancelOrderLoading = true;
        draft.cancelOrderSuccess = false;
        break;
      }
      case '@orders/CANCEL_ORDER_SUCCESS': {
        draft.cancelOrderLoading = false;
        draft.cancelOrderSuccess = true;
        break;
      }
      case '@orders/CANCEL_ORDER_FAILURE': {
        draft.cancelOrderLoading = false;
        draft.cancelOrderSuccess = false;
        break;
      }
      case '@orders/CHANGE_ORDERS_CONFIGS_REQUEST': {
        draft.changeOrdersConfigsLoading = true;
        draft.changeOrdersConfigsSuccess = false;
        break;
      }
      case '@orders/CHANGE_ORDERS_CONFIGS_SUCCESS': {
        draft.changeOrdersConfigsLoading = false;
        draft.changeOrdersConfigsSuccess = true;
        break;
      }
      case '@orders/CHANGE_ORDERS_CONFIGS_FAILURE': {
        draft.changeOrdersConfigsLoading = false;
        draft.changeOrdersConfigsSuccess = false;
        break;
      }
      case  '@orders/SET_QZ_CONNECTED': {
        draft.isQzConnected = action.payload.isConnected;
        break;
      }
      case '@orders/GET_ORDERS_CLEAR_SUCCESS': {
        draft.getOrdersSuccess = false;
        break;
      }
      case '@orders/CANCEL_ORDER_CLEAR_SUCCESS': {
        draft.cancelOrderSuccess = false;
        break;
      }
      case '@orders/CHANGE_ORDERS_CONFIGS_CLEAR_SUCCESS': {
        draft.changeOrdersConfigsSuccess = false;
        break;
      }
      case '@orders/UPDATE_ORDER_STATUS_CLEAR_SUCCESS': {
        draft.updateOrderStatusSuccess = false;
        break;
      }
      case '@orders/SET_CURRENT_ORDER_STATUS_DATA': {
        draft.currOrderStatusData = action.payload.currOrderStatusData;
        break;
      }
      case '@orders/CLEAR_CURRENT_ORDER_STATUS_DATA': {
        draft.currOrderStatusData = {};
        break;
      }
      case '@orders/SET_CURRENT_ORDER_CONFIGS': {
        draft.currOrderConfigs = {
          printOrder: action.payload.printOrder,
          sendWhatsappMessage: action.payload.sendWhatsappMsg,
        };
        break;
      }
      case '@orders/CLEAR_CURRENT_ORDER_CONFIGS': {
        draft.currOrderConfigs = {
          printOrder: false,
          sendWhatsappMessage: false,
        };
        break;
      }
      case '@orders/GET_SEARCHED_ORDER_REQUEST': {
        draft.currSearchedOrderData = null;
        draft.getSearchedOrderLoading = true;
        break;
      }
      case '@orders/GET_SEARCHED_ORDER_SUCCESS': {
        draft.currSearchedOrderData = action.payload.searchedOrderData;
        draft.getSearchedOrderLoading = false;
        break;
      }
      case '@orders/GET_SEARCHED_ORDER_FAILURE': {
        draft.currSearchedOrderData = null;
        draft.getSearchedOrderLoading = false;
        break;
      }
      case '@orders/CLEAR_SEARCHED_ORDER_DATA': {
        draft.currSearchedOrderData = null;
        draft.getSearchedOrderLoading = false;
        break;
      }
      case '@orders/UPDATE_PIX_KEY_STATUS_REQUEST': {
        draft.pixKeyRequestLoading = true;
        draft.pixKeyRequestSuccess = false;
        break;
      }
      case '@orders/UPDATE_PIX_KEY_STATUS_SUCCESS': {
        draft.pixKeyRequestLoading = false;
        draft.pixKeyRequestSuccess = true;
        break;
      }
      case '@orders/UPDATE_PIX_KEY_STATUS_FAILURE': {
        draft.pixKeyRequestLoading = false;
        draft.pixKeyRequestSuccess = false;
        break;
      }
      case '@orders/UPDATE_PIX_KEY_STATUS_CLEAR_SUCCESS': {
        draft.pixKeyRequestSuccess = false;
        break;
      }
      case '@orders/UPDATE_ORDER_PAYMENT_METHOD_REQUEST': {
        draft.updatePaymentMethodLoading = true;
        break;
      }
      case '@orders/UPDATE_ORDER_PAYMENT_METHOD_FAILURE': {
        draft.updatePaymentMethodFailure = true;
        draft.newOrderPaymentMethod = null;
        draft.updatePaymentMethodLoading = false;
        break;
      }
      case '@orders/UPDATE_ORDER_PAYMENT_METHOD_SUCCESS': {
        draft.newOrderPaymentMethod = action.payload.newOrderData;
        draft.updatePaymentMethodLoading = false;
        break;
      }
      case '@orders/CLEAR_NEW_ORDER_PAYMENT_METHOD_DATA': {
        draft.newOrderPaymentMethod = null;
        break;
      }
      case '@orders/CREATE_TEST_ORDER_REQUEST': {
        draft.createTestOrderLoading = true;
        draft.createTestOrderSuccess = false;
        break;
      }
      case '@orders/CREATE_TEST_ORDER_SUCCESS': {
        draft.createTestOrderLoading = false;
        draft.createTestOrderSuccess = true;
        break;
      }
      case '@orders/CREATE_TEST_ORDER_FAILURE': {
        draft.createTestOrderLoading = false;
        draft.createTestOrderSuccess = false;
        break;
      }
      case '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_REQUEST': {
        draft.listToMassActionLoading = true;
        draft.listToMassActionSuccess = false;
        draft.listToMassActionFailure = false;
        break;
      }
      case '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_SUCCESS': {
        draft.listToMassActionLoading = false;
        draft.listToMassActionSuccess = true;
        draft.listToMassActionFailure = false;
        break;
      }
      case '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_FAILURE': {
        draft.listToMassActionLoading = false;
        draft.listToMassActionSuccess = false;
        draft.listToMassActionFailure = true;
        break;
      }
      case '@orders/UPDATE_IDS_LIST': {
        draft.idsListToMassAction = action.payload;
        break;
      }
      case '@orders/UPDATE_ORDERS_ARRAYS': {
        const ordersData = action.payload.ordersData;  
        const newAcceptedOrderObjs = { ...state.newAccepted }
        const preparingOrderObjs = { ...state.preparing }
        const deliveringReadyTakeAwayOrderObjs = { ...state.deliveringReadyTakeAway }

        ordersData.forEach((order) => {
          switch (order.status) {
            case 'accepted':
              newAcceptedOrderObjs[order.order_id] = order;
              draft.newAccepted = newAcceptedOrderObjs;
              draft.ordersQty.totalNewAcceptedOrdersQty = Object.keys(newAcceptedOrderObjs).length;
              break;
            case 'preparing': {
              delete newAcceptedOrderObjs[order.order_id];
              draft.newAccepted = newAcceptedOrderObjs;
              draft.ordersQty.totalNewAcceptedOrdersQty = Object.keys(newAcceptedOrderObjs).length;
              
              preparingOrderObjs[order.order_id] = order;
              draft.preparing = preparingOrderObjs;
              draft.ordersQty.totalPreparingOrdersQty = Object.keys(preparingOrderObjs).length;
              break;
            }
            case 'delivering':
            case 'ready_take_away': {
              delete preparingOrderObjs[order.order_id];
              draft.preparing = preparingOrderObjs;
              draft.ordersQty.totalPreparingOrdersQty = Object.keys(preparingOrderObjs).length;
  
              deliveringReadyTakeAwayOrderObjs[order.order_id] = order;
              draft.deliveringReadyTakeAway = deliveringReadyTakeAwayOrderObjs;
              draft.ordersQty.totalReadyTakeAwayOrdersQty = Object.keys(deliveringReadyTakeAwayOrderObjs).length;
              break;
            }
            case 'canceled':
            case 'finished': {
              delete newAcceptedOrderObjs[order.order_id];
              draft.newAccepted = newAcceptedOrderObjs;
              draft.ordersQty.totalNewAcceptedOrdersQty = Object.keys(newAcceptedOrderObjs).length;
  
              delete preparingOrderObjs[order.order_id];
              draft.preparing = preparingOrderObjs;
              draft.ordersQty.totalPreparingOrdersQty = Object.keys(preparingOrderObjs).length;
  
              delete deliveringReadyTakeAwayOrderObjs[order.order_id];
              draft.deliveringReadyTakeAway = deliveringReadyTakeAwayOrderObjs;
              draft.ordersQty.totalReadyTakeAwayOrdersQty = Object.keys(deliveringReadyTakeAwayOrderObjs).length;
              break;
            }
            default:
              break;
          }
        })
  
        break;
      }
      default:
    }
  });
}
