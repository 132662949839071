export function getOrdersRequest(status) {
  return {
    type: '@orders/GET_ORDERS_REQUEST',
    payload: { status },
  };
}

export function getOrdersSuccess(status, orders, ordersPagination) {
  return {
    type: '@orders/GET_ORDERS_SUCCESS',
    payload: { status, orders, ordersPagination },
  };
}

export function getOrdersFailure(status) {
  return {
    type: '@orders/GET_ORDERS_FAILURE',
    payload: { status },
  };
}

export function getOrdersWithoutLoaderRequest() {
  return {
    type: '@orders/GET_ORDERS_WITHOUT_LOADER_REQUEST',
    payload: { status: 'new' },
  };
}

export function getNewAcceptedOrdersWithoutLoaderRequest() {
  return {
    type: '@orders/GET_NEW_ACCEPTED_ORDERS_WITHOUT_LOADER_REQUEST',
    payload: { status: 'new_accepted' },
  };
}

export function getOrdersWithoutLoaderSuccess(
  status,
  orders,
  ordersPagination
) {
  return {
    type: '@orders/GET_ORDERS_WITHOUT_LOADER_SUCCESS',
    payload: { status, orders, ordersPagination },
  };
}

export function getOrdersWithoutLoaderFailure() {
  return {
    type: '@orders/GET_ORDERS_WITHOUT_LOADER_FAILURE',
  };
}

export function getOrderDetailRequest(orderId) {
  return {
    type: '@orders/GET_ORDER_DETAIL_REQUEST',
    payload: { orderId },
  };
}

export function getOrderDetailSuccess(orderDetail) {
  return {
    type: '@orders/GET_ORDER_DETAIL_SUCCESS',
    payload: { orderDetail },
  };
}

export function getOrderDetailFailure() {
  return {
    type: '@orders/GET_ORDER_DETAIL_FAILURE',
  };
}

export function updateOrderStatusRequest(
  orderData,
  newStatus,
) {
  return {
    type: '@orders/UPDATE_ORDER_STATUS_REQUEST',
    payload: { 
      orderData, 
      newStatus
    },
  };
}

export function updateOrderStatusSuccess() {
  return {
    type: '@orders/UPDATE_ORDER_STATUS_SUCCESS',
  };
}

export function updateOrderStatusFailure() {
  return {
    type: '@orders/UPDATE_ORDER_STATUS_FAILURE',
  };
}

export function cancelOrderRequest(orderData, newStatus) {
  return {
    type: '@orders/CANCEL_ORDER_REQUEST',
    payload: { orderData, newStatus },
  };
}

export function cancelOrderSuccess() {
  return {
    type: '@orders/CANCEL_ORDER_SUCCESS',
  };
}

export function cancelOrderFailure() {
  return {
    type: '@orders/CANCEL_ORDER_FAILURE',
  };
}

export function getOrdersClearSuccess() {
  return {
    type: '@orders/GET_ORDERS_CLEAR_SUCCESS',
  };
}

export function cancelOrderClearSuccess() {
  return {
    type: '@orders/CANCEL_ORDER_CLEAR_SUCCESS',
  };
}

export function updateOrderStatusClearSuccess() {
  return {
    type: '@orders/UPDATE_ORDER_STATUS_CLEAR_SUCCESS',
  };
}

export function changeOrdersConfigsRequest(
  printType,
  maxCharLine,
  sendWhatsappMsgUpdateStatus,
  printOrderInvoiceAcceptOrder,
  isUsingChatbot,
  printMethod,
  printerName,
  maxLineCharsAutomatic
) {
  return {
    type: '@orders/CHANGE_ORDERS_CONFIGS_REQUEST',
    payload: {
      printType,
      maxCharLine,
      sendWhatsappMsgUpdateStatus,
      printOrderInvoiceAcceptOrder,
      isUsingChatbot,
      printMethod,
      printerName,
      maxLineCharsAutomatic
    },
  };
}

export function setQzConnected (isConnected) {
  return {
    type: '@orders/SET_QZ_CONNECTED',
    payload: {
      isConnected
    }
  }
}

export function changeOrdersConfigsSuccess() {
  return {
    type: '@orders/CHANGE_ORDERS_CONFIGS_SUCCESS',
  };
}

export function changeOrdersConfigsFailure() {
  return {
    type: '@orders/CHANGE_ORDERS_CONFIGS_FAILURE',
  };
}

export function changeOrdersConfigsClearSuccess() {
  return {
    type: '@orders/CHANGE_ORDERS_CONFIGS_CLEAR_SUCCESS',
  };
}

export function formatOrdersComplements(
  referrerFunc,
  status,
  orders,
  ordersPagination
) {
  return {
    type: '@orders/FORMAT_ORDERS_COMPLEMENTS',
    payload: { referrerFunc, status, orders, ordersPagination },
  };
}

export function changeAcceptingOrdersRequest(
  acceptingImmediate,
  acceptingScheduled
) {
  return {
    type: '@orders/CHANGE_ACCEPTING_ORDERS_REQUEST',
    payload: { acceptingImmediate, acceptingScheduled },
  };
}

export function changeAcceptingOrdersSuccess(advertiserInfo) {
  return {
    type: '@orders/CHANGE_ACCEPTING_ORDERS_SUCCESS',
    payload: { advertiserInfo },
  };
}

export function changeAcceptingOrdersFailure() {
  return {
    type: '@orders/CHANGE_ACCEPTING_ORDERS_FAILURE',
  };
}

export function getOrdersPaginatedRequest(status, paginationPage) {
  return {
    type: '@orders/GET_ORDERS_PAGINATED_REQUEST',
    payload: { status, paginationPage },
  };
}

export function getOrdersPaginatedSuccess(status, orders, ordersPagination) {
  return {
    type: '@orders/GET_ORDERS_PAGINATED_SUCCESS',
    payload: { status, orders, ordersPagination },
  };
}

export function getOrdersQtySuccess(ordersQty) {
  return {
    type: '@orders/GET_ORDERS_QTY_SUCCESS',
    payload: { ordersQty },
  };
}

export function setCurrOrderStatusData(currOrderStatusData) {
  return {
    type: '@orders/SET_CURRENT_ORDER_STATUS_DATA',
    payload: { currOrderStatusData },
  };
}

export function clearCurrOrderStatusData() {
  return {
    type: '@orders/CLEAR_CURRENT_ORDER_STATUS_DATA',
  };
}

export function setCurrOrderConfigs(printOrder, sendWhatsappMsg) {
  return {
    type: '@orders/SET_CURRENT_ORDER_CONFIGS',
    payload: { printOrder, sendWhatsappMsg },
  };
}

export function clearCurrOrderConfigs() {
  return {
    type: '@orders/CLEAR_CURRENT_ORDER_CONFIGS',
  };
}

export function getSearchedOrderRequest(orderId) {
  return {
    type: '@orders/GET_SEARCHED_ORDER_REQUEST',
    payload: { orderId },
  };
}

export function getSearchedOrderSuccess(searchedOrderData) {
  return {
    type: '@orders/GET_SEARCHED_ORDER_SUCCESS',
    payload: { searchedOrderData },
  };
}

export function getSearchedOrderFailure() {
  return {
    type: '@orders/GET_SEARCHED_ORDER_FAILURE',
  };
}

export function clearSearchedOrderData() {
  return {
    type: '@orders/CLEAR_SEARCHED_ORDER_DATA',
  };
}

export function updatePixKeyPaymentRequest(orderPaymentData) {
  return {
    type: '@orders/UPDATE_PIX_KEY_STATUS_REQUEST',
    payload: { orderPaymentData },
  };
}

export function updatePixKeyPaymentFailure() {
  return {
    type: '@orders/UPDATE_PIX_KEY_STATUS_FAILURE',
  };
}

export function updatePixKeyPaymentSuccess() {
  return {
    type: '@orders/UPDATE_PIX_KEY_STATUS_SUCCESS',
  };
}

export function clearUpdatePixKeyPaymentSuccess() {
  return {
    type: '@orders/UPDATE_PIX_KEY_STATUS_CLEAR_SUCCESS',
  };
}

export function updateOrderPaymentMethodRequest(orderId, olderOrderPMId, newOrderPMId) {
  return {
    type: '@orders/UPDATE_ORDER_PAYMENT_METHOD_REQUEST',
    payload: { orderId, olderOrderPMId, newOrderPMId }
  };
}

export function updateOrderPaymentMethodSuccess(newOrderData) {
  return {
    type: '@orders/UPDATE_ORDER_PAYMENT_METHOD_SUCCESS',
    payload: { newOrderData }
  };
}

export function updateOrderPaymentMethodFailure() {
  return {
    type: '@orders/UPDATE_ORDER_PAYMENT_METHOD_FAILURE',
  };
}

export function clearNewOrderPaymentMethodData() {
  return {
    type: '@orders/CLEAR_NEW_ORDER_PAYMENT_METHOD_DATA',
  };
}

export function createTestOrderRequest() {
  return {
    type: '@orders/CREATE_TEST_ORDER_REQUEST',
  };
}

export function createTestOrderSuccess() {
  return {
    type: '@orders/CREATE_TEST_ORDER_SUCCESS',
  };
}

export function createTestOrderFailure() {
  return {
    type: '@orders/CREATE_TEST_ORDER_FAILURE',
  };
}

export function getClientOrdersData(ordersIds, conversationId) {
  return {
    type: '@orders/GET_CLIENT_ORDERS_DATA',
    payload: { 
      ordersIds,
      conversationId
    }
  };
}

export function updateMultipleOrdersStatusesRequest(idOrderslist, cancelOrder) {
  return {
    type: '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_REQUEST',
    payload: {idOrderslist, cancelOrder}
  }
}

export function updateMultipleOrdersStatusesSuccess() {
  return {
    type: '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_SUCCESS'
  }
}

export function updateMultipleOrdersStatusesFailure() {
  return {
    type: '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_FAILURE'
  }
}

export function updateIdsList(idOrderslist) {
  return {
    type: '@orders/UPDATE_IDS_LIST',
    payload: idOrderslist
  }
}

export function updateOrdersArrays(ordersData) {
  return {
    type: '@orders/UPDATE_ORDERS_ARRAYS',
    payload: { ordersData }
  }
}
