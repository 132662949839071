import { cpfCnpjMask, dddPhoneMask, ddiPhoneMask } from '../../masks';

export function formatInvoiceCommonData(orderStatusData, advertiserData) {
  let advertiser = '';
  let client = '';
  let order = '';

  // Advertiser Data
  const advertiserAddressComplement = advertiserData.address[0].complement
    ? ` - ${advertiserData.address[0].complement}`
    : '';

  advertiser = {
    name: advertiserData.advertiserInfo.name,
    cnpj: cpfCnpjMask(advertiserData.advertiserInfo.cpf_cnpj),
    phone: dddPhoneMask(advertiserData.address[0].phone_1),
    address: `${advertiserData.address[0].street}, ${advertiserData.address[0].number}${advertiserAddressComplement} - ${advertiserData.address[0].neighborhood} - ${advertiserData.address[0].city} - ${advertiserData.address[0].state} - CEP: ${advertiserData.address[0].zipcode}`,
  };

  // Client Data
  const clientAddressComplement = orderStatusData.order.address.complement
    ? ` - Comp. ${orderStatusData.order.address.complement}`
    : '';
  const zipCode = orderStatusData.order.address.zipcode
    ? ` - CEP: ${orderStatusData.order.address.zipcode}`
    : '';

  client = {
    name: orderStatusData.order.clientInfo.name || 'Não informado',
    phone: orderStatusData.order.clientInfo.phone
      ? ddiPhoneMask(orderStatusData.order.clientInfo.phone)
      : '-',
    address: `${orderStatusData.order.address.street}, ${orderStatusData.order.address.number}${clientAddressComplement} - ${orderStatusData.order.address.neighborhood} - ${orderStatusData.order.address.city} - ${orderStatusData.order.address.state}${zipCode}`,
  };

  // If take away order, change the address to text informing that the user will take away the order
  if (orderStatusData.order.delivery_type === 'take_away') {
    client.address = 'Cliente vai retirar na loja';
  }

  // Order Data
  order = {
    paid_amount:
      Number(orderStatusData.order.change) > Number(0)
        ? Number(orderStatusData.order.change).toFixed(2)
        : Number(orderStatusData.order.total_amount).toFixed(2),
    change:
      orderStatusData.order.paymentMethod.slug === 'cash' &&
      Number(orderStatusData.order.change) > Number(0)
        ? `${(
            Number(orderStatusData.order.change) -
            Number(orderStatusData.order.total_amount)
          ).toFixed(2)}`
        : 'Não precisa',
    delivery_type:
      orderStatusData.order.delivery_type === 'delivery'
        ? 'Delivery'
        : 'Retirada',
    order_type:
      orderStatusData.order.order_type === 'scheduled'
        ? 'Agendado para'
        : 'Pronta Entrega',
  };

  return {
    advertiser,
    client,
    order,
  };
}

//TextPlain Format Functions
export function includeSpace(qty) {
  return '&nbsp;'.repeat(qty);
}

export function centralizeText(text, maxCharLine) {
  const qtySpaces = (maxCharLine - text.length) / 2;
  return `${includeSpace(qtySpaces)}${text}`;
}

export function rightText(text, maxCharLine) {
  const qtySpaces = maxCharLine - Number(text.replace(/&nbsp;/g, ' ').length);
  return `${includeSpace(qtySpaces)}${text}`;
}

export function justifyText(text1, text2, maxCharLine) {
  const qtySpaces =
    maxCharLine -
    (Number(text1.length) + Number(text2.replace(/&nbsp;/g, ' ').length));
  return `${text1}${includeSpace(qtySpaces)}${text2}`;
}

export function leftText(text, maxCharLine) {
  const qtySpaces = maxCharLine - Number(text.replace(/&nbsp;/g, ' ').length);
  return `${text}${includeSpace(qtySpaces)}`;
}

export function formatAmount(amount, maxCharAmount) {
  const qtySpaces = maxCharAmount - Number(amount.length);
  return `${includeSpace(qtySpaces)}${amount}`;
}

export function formatAddress(address, addressLabelLength, maxCharLine) {
  let formatedAddress = '';

  if (address.length + Number(addressLabelLength) > maxCharLine) {
    // Split the address line 1
    const regexAddressLine1 = new RegExp(
      `.{1,${Number(maxCharLine) - Number(addressLabelLength)}}`,
      'g'
    );

    let addressArr = address.match(regexAddressLine1);
    const addrLine1 = addressArr[0];

    // Split the others address lines
    const regexAddress = new RegExp(`.{1,${maxCharLine}}`, 'g');
    addressArr = address
      .substring(address.indexOf(addrLine1) + addrLine1.length, address.length)
      .match(regexAddress);

    // Add other address lines
    formatedAddress = `${addrLine1}<br>`;
    if (addressArr) {
      addressArr.forEach((addressLine) => {
        formatedAddress += `${addressLine}<br>`;
      });
    }
  } else {
    formatedAddress = address;
  }

  return formatedAddress;
}
