import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../styles/grid';
import { DetailButton, ImgContainer } from '../../styles';
import { PaginateContainer } from '../../../_layouts/styles/common';
import { preto } from '../../../../styles/colors';

import { Arrow, Arrow2, SvgBox } from './icons';
import { Content, ErrorText, Header, HeaderButton, Hr, TableRow } from './styles';
import { sort } from './tableOptions';
import OrderDetailModal from '../../../../components/OrderDetailModal';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { clearSearchedOrderData, getSearchedOrderRequest } from '../../../../store/modules/orders/actions';
import { getRatingsPaginatedRequest } from '../../../../store/modules/ratings/actions';

export default function RatingsTable({ data, pagination }) {
  const fullStar = require("../../../../assets/images/ratings/star.png");
  const halfStar = require("../../../../assets/images/ratings/star_half_full.png");
  const emptyStar = require("../../../../assets/images/ratings/star_empty.png");
  const [currRatingsList, setCurrRatingsList] = useState(data);
  const [paginationData, setPaginationData] = useState(pagination);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [sortedCol, setSortedCol] = useState('');
  const currSearchedOrderData = useSelector((state) => state.orders.currSearchedOrderData)
  const { 
    ratingsList,
    ratingsPagination,
    getRatingsLoading, 
    getRatingsPaginationLoading
  } = useSelector((state) => state.ratings);
  const dispatch = useDispatch();  

  useEffect(() => {
    setCurrRatingsList(ratingsList);
  }, [ratingsList]);

  useEffect(() => {
    setPaginationData(ratingsPagination);
  }, [ratingsPagination]);

  function handleChangePage(pageSelected) {
    dispatch(getRatingsPaginatedRequest(pageSelected.selected + 1));
  }

  function handleDateFormat(date) {
    return moment(new Date(date)).format('DD/MM/YYYY HH:mm')
  }

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setCurrRatingsList(sort(currRatingsList, col, 'asc'));
      } else {
        setSortedCol('');
        setCurrRatingsList(sort(currRatingsList, col, 'desc'));
      }
    },
    [currRatingsList]
  );

  const HandleStarRating = ({ stars }) => {
    let arr = []
    for (let i = 0; i < Math.floor(stars); i++) {
      arr.push(
        <ImgContainer
          width={px2vw(15)}
          key={`#${i}`}
          height={px2vw(15)}
          src={fullStar} 
        />
      )
    }
    let leftovers = stars?.toString().split(".")[1];
    if (leftovers > 0) {
      arr.push(<ImgContainer src={halfStar} key={`#leftover${leftovers}`} />)
    }
    while (arr.length < 5) {
      arr.push(<ImgContainer width={px2vw(15)} height={px2vw(15)} key={`#imgKey${stars}Id`} src={emptyStar} />)
    }
    return (
      <>
        {arr.map((starImg) => {
          return starImg
        })}
      </>
    )
  }

  return (
    <>
      <Col padding="0 15px" alignCenter justifyCenter padbottom={px2vw(10)}>
        <Row>
          <Header width="65%" onClick={() => handleSort('client_name')}>
            <HeaderButton>
              Nome do Cliente
              <SvgBox sorted={sortedCol === 'client_name'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width="70%" onClick={() => handleSort('order_id')}>
            <HeaderButton>
              ID do Pedido
              <SvgBox sorted={sortedCol === 'order_id'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width="70%" onClick={() => handleSort('order_created_at')}>
            <HeaderButton>
              Data do Pedido
              <SvgBox sorted={sortedCol === 'order_created_at'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width="50%" onClick={() => handleSort('order_rating')}>
            <HeaderButton>
              Avaliação
              <SvgBox sorted={sortedCol === 'order_rating'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width="80%">
            Detalhes do Pedido
          </Header>
        </Row>
        <Row>
          <Hr />
        </Row>
        {!getRatingsLoading && !getRatingsPaginationLoading && currRatingsList?.length > 0 && 
          currRatingsList?.map((rating) => ( 
            <Fragment key={`rating-${rating?.id}`}>
              <TableRow alignCenter justifyCenter>
                <Content width="70%">
                  {rating?.client_name}
                </Content>
                <Content width="60%">
                  #{rating?.order_id}
                </Content>
                <Content width="64%">
                  {handleDateFormat(rating?.order_created_at)}
                </Content>
                <Content width="50%">
                  <HandleStarRating stars={Math.floor(rating?.order_rating)}/>
                </Content>
                <Content width="75%">
                  <DetailButton
                    margtop={px2vw(5)}
                    margleft={px2vw(1)}
                    onClick={() => {
                      dispatch(getSearchedOrderRequest(rating?.order_id));
                      setOrderDetailModal(true);
                    }}>
                    Ver Detalhes
                  </DetailButton>
              </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
        ))}
        {!getRatingsLoading && !getRatingsPaginationLoading && currRatingsList?.length === 0 && (
          <Col height="20vh">
            <Row alignCenter justifyCenter>
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>Você não tem avaliações a serem exibidas.</ErrorText>
            </Row>
          </Col>
        )}
        {(getRatingsLoading || getRatingsPaginationLoading) && (
          <Col height="20vh">
            <Col alignCenter justifyCenter>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </Col>
          </Col>
        )}
        {/* Pagination Controls */}
        {currRatingsList?.length > 1 && (
          <Row justifyEnd margright={px2vw(100)}>
            <PaginateContainer>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={paginationData.lastPage}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handleChangePage}
                containerClassName="pagination"
                pageClassName="page"
                activeClassName="active"
                breakClassName="break_cn"
                previousClassName="previous_cn"
                nextClassName="next_cn"
                disabledClassName="disabled_cn"
                previousLinkClassName="previous_a_cn"
                nextLinkClassName="next_a_cn"
                disabledLinkClassName="disabled_a_cn"
              />
            </PaginateContainer>
          </Row>
        )}
      </Col>
      {/* Order Detail Modal - Table click - DO NOT wrap this component to not be rendered */}
      <OrderDetailModal
        isVisible={orderDetailModal}
        orders={currSearchedOrderData || []}
        hasNavigation={false}
        orderIndex={0}
        onModalResponse={() => {
          setOrderDetailModal(false);
          dispatch(clearSearchedOrderData());
        }}
        onScreen="ratings"
      />
    </>
  );
}