import moment from 'moment';

export function only2DecimalPlaces(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    const numberParts = number.split('.');
    if (numberParts.length > 1) {
      return numberParts[0] + '.' + numberParts[1].substring(0, 2);
    }
    return numberParts[0];
  }
  return '0';
}

export function onlyNumbers(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/\D/g, '');
  }
  return '';
}

export function onlyNumbersAmount(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/R\$ /g, '');
  }
  return '';
}

export function formatOrderNumber(orderNumber) {
  if (orderNumber !== null && orderNumber !== undefined && orderNumber !== '') {
    const formattedOrderNumber = orderNumber.toString();
    return `#${formattedOrderNumber}`;
  }
  return '';
}

export function formatCurrencyBRL(value, options) {
  const strValue = value === Infinity ? 0 : value;
  const maximumFractionDigits = options.maximumFractionDigits || 2;
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    maximumFractionDigits,
    ...options,
  });
  return formatter.format(strValue);
}

export function formatCreateDateTimeOrder(orderDateTime) {
  const orderDate = moment(orderDateTime, 'DD/MM/YYYY HH:mm', true).format(
    'DD/MM/YYYY'
  );

  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  if (orderDate === currentDate) {
    return `Pedido realizado hoje às ${moment(
      orderDateTime,
      'DD/MM/YYYY HH:mm',
      true
    ).format('HH:mm')}`;
  }

  return `Pedido realizado em ${moment(
    orderDateTime,
    'DD/MM/YYYY HH:mm',
    true
  ).format('DD/MM/YYYY à\\s HH:mm')}`;
}

export function formatDateTimeOrderText(orderDateTime, status) {
  let msgOrderStatusDate;
  if (status === 'new') {
    msgOrderStatusDate = 'Aguardando confirmação';
  } else if (status === 'accepted') {
    msgOrderStatusDate = 'Aceito ';
  } else if (status === 'preparing') {
    msgOrderStatusDate = 'Iniciado preparo ';
  } else if (status === 'delivering') {
    msgOrderStatusDate = 'Saiu para entrega ';
  } else if (status === 'ready_take_away') {
    msgOrderStatusDate = 'Pronto para retirada ';
  } else if (status === 'finished') {
    msgOrderStatusDate = 'Concluído ';
  } else if (status === 'canceled') {
    msgOrderStatusDate = 'Cancelado ';
  }

  const orderDate = moment(orderDateTime, 'DD/MM/YYYY HH:mm', true).format(
    'DD/MM/YYYY'
  );
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  if (orderDate !== currentDate && status !== 'new') {
    return `${msgOrderStatusDate} em `;
  }

  return msgOrderStatusDate;
}

export function formatDateTimeOrder(orderDateTime) {
  const orderDate = moment(orderDateTime, 'DD/MM/YYYY HH:mm', true).format(
    'DD/MM/YYYY'
  );
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  if (orderDate === currentDate) {
    return `hoje às ${moment(orderDateTime, 'DD/MM/YYYY HH:mm', true).format(
      'HH:mm'
    )}`;
  }

  return moment(orderDateTime, 'DD/MM/YYYY HH:mm', true).format(
    'DD/MM/YYYY à\\s HH:mm'
  );
}

export function formatDateTimeSchuduledOrder(schedulingOrderDateTime) {
  const schedulingOrderDate = moment(
    schedulingOrderDateTime.scheduling_start_at,
    'DD/MM/YYYY HH:mm',
    true
  ).format('DD/MM/YYYY');
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  if (schedulingOrderDate === currentDate) {
    return `Agendamento - Hoje - ${moment(
      schedulingOrderDateTime.scheduling_start_at,
      'DD/MM/YYYY HH:mm',
      true
    ).format('HH:mm')} a ${moment(
      schedulingOrderDateTime.scheduling_end_at,
      'DD/MM/YYYY HH:mm',
      true
    ).format('HH:mm')}`;
  }

  return `Agendamento - ${moment(
    schedulingOrderDateTime.scheduling_start_at,
    'DD/MM/YYYY HH:mm',
    true
  ).format('DD/MM - HH:mm')} a ${moment(
    schedulingOrderDateTime.scheduling_end_at,
    'DD/MM/YYYY HH:mm',
    true
  ).format('HH:mm')}`;
}

export function definePlanPeriodBasedValidity(
  startValidity,
  finishValidity,
  isFree,
  planPeriod
) {
  let definedPlanPeriod = '-';

  if (!isFree) {
    if (planPeriod === 1) {
      definedPlanPeriod = `${planPeriod} mês`;
    } else {
      definedPlanPeriod = `${planPeriod} meses`;
    }
  } else {
    const finishValidityArray = finishValidity.split('/');
    const finishValidityDate = moment(
      new Date(
        `${finishValidityArray[2]}-${finishValidityArray[1]}-${finishValidityArray[0]}T00:00:00`
      )
    );
    const startValidityArray = startValidity.split('/');
    const startValidityDate = moment(
      new Date(
        `${startValidityArray[2]}-${startValidityArray[1]}-${startValidityArray[0]}T00:00:00`
      )
    );

    definedPlanPeriod = finishValidityDate.diff(startValidityDate, 'days');

    if (definedPlanPeriod < 30) {
      definedPlanPeriod = `${definedPlanPeriod} dias`;
    } else if (definedPlanPeriod === 30) {
      definedPlanPeriod = `1 mês`;
    } else {
      definedPlanPeriod = `${Math.round(definedPlanPeriod / 30)} meses`;
    }
  }

  return definedPlanPeriod;
}

export function definePlanName(
  startValidity,
  finishValidity,
  isFree,
  planPeriod,
  planName,
  isFirstSteps = false
) {
  let definedPlanName = '-';

  if (!isFree) {
    definedPlanName = planName;
  } else {
    const definedPlanPeriod = definePlanPeriodBasedValidity(
      startValidity,
      finishValidity,
      isFree,
      planPeriod
    );

    if (definedPlanPeriod.includes('dias')) {
      definedPlanName = `${definedPlanPeriod} grátis`;
    } else if (definedPlanPeriod.includes('mês')) {
      if (isFirstSteps) {
        definedPlanName = `um mês grátis`;
      } else {
        definedPlanName = `Mês grátis`;
      }
    } else {
      definedPlanName = `${definedPlanPeriod} gratuitos`;
    }
  }

  return definedPlanName;
}

export function getOrderRequestStatusByTab(selectedTab) {
  switch (selectedTab) {
    case 'tabNew':
      return 'new_accepted';
    case 'tabPreparing':
      return 'preparing';
    case 'tabDeliveringReadyTakeAway':
      return 'delivering_ready_take_away';
    case 'tabFinished':
      return 'finished';
    default:
      break;
  }
}

export function getOrderStateNameByTab(selectedTab) {
  switch (selectedTab) {
    case 'tabPreparing':
      return 'preparing';
    case 'tabDeliveringReadyTakeAway':
      return 'deliveringReadyTakeAway';
    case 'tabFinished':
      return 'finished';
    default:
      return 'newAccepted';
  }
}
  
export function getOrderStateNameByStatus(orderStatus) {
  switch (orderStatus) {
    case 'new':
    case 'new_accepted':
      return 'newAccepted';
    case 'preparing':
      return 'preparing';
    case 'delivering_ready_take_away':
    case 'delivering':
    case 'ready_take_away':
      return 'deliveringReadyTakeAway';
    default:
      return 'finished';
  }
}

export function formatArrayAsObject(array = [], key) {
  const objects = {};
  array.forEach((item) => {
    if (item[key]) {
      objects[item[key]] = item;
    }
  });

  return objects;
}

export function openWhatsAppWebMGM(link) {
  const text = `Olá! Tudo certo?

Estou passando para avisar sobre uma ferramenta top e completa que estou utilizando no meu delivery, ela tem:

✅ Chatbot para atendimento no whatsapp
✅ Cardápio digital top!
✅ Criação de Anúncios patrocinados automáticos nas redes sociais

Você pode se cadastrar e testar por 7 dias grátis! Só clicar aqui 👇

${link}`

  const message = window.encodeURIComponent(text);

  window.open(
    `https://api.whatsapp.com/send/?phone=&text=${message}`
  );
}

export function getURL(urlType) {
  const reactAppEnv = process.env.REACT_APP_ENV_ALL_API;
  const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
  const reactAppEnvAnunciante = process.env.REACT_APP_ENV_ADVERTISER_API;
  const reactAppEnvWhatsappbot = process.env.REACT_APP_ENV_WHATSAPPBOT_API;
  const reactAppEnvWebsocket = process.env.REACT_APP_ENV_WEBSOCKET_API;
  const reactAppEnvWppWebsocket = process.env.REACT_APP_ENV_WHATSAPPCHAT_WEBSOCKET;

  const baseUrlsAnunciante = {
    production: 'https://portal.kuppi.com.br/',
    staging: 'https://stg-portal.kuppi.com.br/',
    qa: 'https://qa-portal.kuppi.com.br/',
    development: 'https://dev-portal.kuppi.com.br/',
    local: 'http://localhost:3000/',
  };

  const baseUrlsLandingPage = {
    production: 'https://kuppi.com.br/',
    staging: 'https://stg.kuppi.com.br/',
    qa: 'https://qa.kuppi.com.br/',
    development: 'https://dev.kuppi.com.br/',
    local: 'http://localhost:3001/',
  };

  const baseUrlsCardapioWeb = {
    production: 'https://$$advertiser_slug$$.kuppi.app',
    staging: 'https://$$advertiser_slug$$.stg.kuppi.app',
    qa: 'https://$$advertiser_slug$$.qa.kuppi.app',
    development: 'https://$$advertiser_slug$$.dev.kuppi.app',
    local: 'http://$$advertiser_slug$$.kuppi.app.local:3002',
  };

  const baseAPIsUrlsAnunciante = {
    production: 'https://api.kuppi.com.br/anunciante',
    staging: 'https://stg-api.kuppi.com.br/anunciante',
    qa: 'https://qa-api.kuppi.com.br/anunciante',
    development: 'https://dev-api.kuppi.com.br/anunciante',
    local: 'http://localhost:3333/anunciante',
  };

  const baseAPIsUrlsGlobal = {
    production: 'https://api.kuppi.com.br/global',
    staging: 'https://stg-api.kuppi.com.br/global',
    qa: 'https://qa-api.kuppi.com.br/global',
    development: 'https://dev-api.kuppi.com.br/global',
    local: 'http://localhost:3334/global',
  };

  const baseAPIsUrlsWS = {
    production: 'https://ms.kuppi.com.br',
    staging: 'https://stg-ms.kuppi.com.br',
    development: 'https://dev-ms.kuppi.com.br',
    qa: 'https://qa-ms.kuppi.com.br',
    local: 'http://localhost:3335',
  };

  const baseAPIsUrlsWSWPP = {
    production: 'https://wpp.kuppi.com.br' ,
    staging: 'https://stg-wpp.kuppi.com.br',
    development: 'https://dev-wpp.kuppi.com.br',
    qa: 'https://qa-wpp.kuppi.com.br',
  };

  const baseAPIsUrlsWSWPPHTTP = {
    ...baseAPIsUrlsWSWPP,
    local: 'http://localhost:3001',
  };

  const baseAPIsUrlsWSWPPSocket = {
    ...baseAPIsUrlsWSWPP,
    local: 'http://localhost:3335',
  };

  const baseAPIsUrlsWhatsappbot = {
    production: 'https://wpp.kuppi.com.br/anunciante-whatsappbot',
    staging: 'https://stg-wpp.kuppi.com.br/anunciante-whatsappbot',
    qa: 'https://qa-wpp.kuppi.com.br/anunciante-whatsappbot',
    development: 'https://dev-wpp.kuppi.com.br/anunciante-whatsappbot',
    local: 'http://localhost:3336/anunciante-whatsappbot',
  };

  if (urlType === 'global_api') {
    return baseAPIsUrlsGlobal[reactAppEnv || reactAppEnvGlobal];
  }
  if (urlType === 'anunciante_api') {
    return baseAPIsUrlsAnunciante[reactAppEnv || reactAppEnvAnunciante];
  }
  if (urlType === 'anunciante_whatsappbot_api_whatsmeow') {
    return baseAPIsUrlsWhatsappbot[reactAppEnv || reactAppEnvWhatsappbot];
  }
  if (urlType === 'ws') {
    return baseAPIsUrlsWS[reactAppEnv || reactAppEnvWebsocket];
  }
  if (urlType === 'wsWppSocket') {
    return baseAPIsUrlsWSWPPSocket[reactAppEnv || reactAppEnvWppWebsocket];
  }
  if (urlType === 'wsWppHttp') {
    return baseAPIsUrlsWSWPPHTTP[reactAppEnv || reactAppEnvWppWebsocket];
  }
  if (urlType === 'cardapio_web') {
    return baseUrlsCardapioWeb[reactAppEnv || reactAppEnvAnunciante];
  }
  if (urlType === 'anunciante') {
    return baseUrlsAnunciante[reactAppEnv || reactAppEnvAnunciante];
  }
  if (urlType === 'landing') {
    return baseUrlsLandingPage[reactAppEnv || reactAppEnvAnunciante];
  }

  return 'invalid urlType';
}


export function scrollTop(){
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
