import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { Col, Row } from "../../../styles/grid";
import { Hr } from "../ClientsTable/styles";
import { CloseImage } from "../../../components/Modal/styles";
import { FidelityBar, TitleStyleText } from "../styles";
import { cinzaKuppi4, verdeKuppi1 } from "../../../styles/colors";

import ClientDetailsTable from "../ClientDetailsTable";
import Modal from "../../../components/Modal";

import px2vw from "../../../utils/responsiveness/px2vw";
import { dddPhoneMask } from "../../../utils/masks";
import { formatCurrencyBRL } from "../../../utils/utils";

export default function ClientDetailsModal({ setIsDetailsModalOpen }) {
  const clientDetails = useSelector((state) => state.clients.clientDetails);
  const clientDetailsLoading = useSelector((state) => state.clients.clientDetailsLoading);

  function GenerateFidelityBars(barsQty, color) {
    return Array.from(Array(Number(barsQty)).keys()).map((i) => {
      return <FidelityBar key={i} bgColor={color} />
    })
  }

  function CompletedBars() {
    return GenerateFidelityBars(clientDetails.loyalty_card_data.total_orders_of_card, verdeKuppi1);
  }

  function EmptyBars() {
    return GenerateFidelityBars(clientDetails.loyalty_card_data.amount_of_purchases - clientDetails.loyalty_card_data.total_orders_of_card, cinzaKuppi4)
  }

  function formatDateTime(dateToFormat) {
    const lastOrderDate = moment(
      dateToFormat,
      'DD/MM/YYYY HH:mm:ss',
      true
    ).format('DD/MM/YYYY');
    const currentDate = moment(new Date()).format('DD/MM/YYYY');

    if (lastOrderDate === currentDate) {
      return `Hoje às ${moment(
        dateToFormat,
        'DD/MM/YYYY HH:mm:ss',
        true
      ).format('HH:mm')}`;
    }

    return moment(dateToFormat, 'DD/MM/YYYY HH:mm:ss', true).format(
      'DD/MM/YYYY à\\s HH:mm'
    );
  }

  function formatDate(dateToFormat) {
    const lastOrderDate = moment(
      dateToFormat,
      'DD/MM/YYYY',
      true
    ).format('DD/MM/YYYY');
    const currentDate = moment(new Date()).format('DD/MM/YYYY');

    if (lastOrderDate === currentDate) {
      return `${moment(dateToFormat, 'DD/MM/YYYY', true)} (Hoje)`;
    }

    return moment(dateToFormat, 'DD/MM/YYYY', true).format('DD/MM/YYYY');
  }

  function formatDiscount(loyaltyCardData) {
    switch (loyaltyCardData.type) {
      case 'fixed_cart':
        return formatCurrencyBRL(loyaltyCardData.discount_fixed.toFixed(2), {});
      case 'percentage_cart':
        return `${loyaltyCardData.discount_percentage}%`;
      default:
        return '-';
    }
  }
  
  return (
    <Modal width={px2vw(900)} height={px2vw(600)} isVisible={true} padding={px2vw(20)}>
      <Row spaceBetween>
        <TitleStyleText isModalTitle>Detalhes do Cliente</TitleStyleText>
        <CloseImage
          src={require('../../../assets/images/close/close.png')}
          onClick={() => { setIsDetailsModalOpen(false) }}
        />
      </Row>
      {!clientDetailsLoading && clientDetails.client_details && clientDetails.client_details.length > 0 && (
        <>
          <Row spaceAround margtop={px2vw(10)} margbottom={px2vw(20)}>
            <Col>
              <TitleStyleText isModalSubTitle>Nome</TitleStyleText>
              <TitleStyleText isModalSubText>{clientDetails.client_details[0].name || 'Não informado'}</TitleStyleText>
            </Col>
            <Col>
              <TitleStyleText isModalSubTitle>Whatsapp</TitleStyleText>
              <TitleStyleText isModalSubText>{clientDetails.client_details[0].phone === null ? 'Não informado' : `${dddPhoneMask(clientDetails.client_details[0].phone)}`}</TitleStyleText>
            </Col>
            <Col>
              <TitleStyleText isModalSubTitle>Última Compra</TitleStyleText>
              <TitleStyleText isModalSubText>{formatDateTime(clientDetails.client_details[0].created_at)}</TitleStyleText>
            </Col>
            <Col>
              <TitleStyleText isModalSubTitle>Total de Compras</TitleStyleText>
              <TitleStyleText isModalSubText>{clientDetails.client_details.length || 0}</TitleStyleText>
            </Col>
          </Row>
          <Hr color={cinzaKuppi4} />
          <Row justifyStart margtop={px2vw(10)} margbottom={px2vw(20)}>
            <TitleStyleText isModalTitle >Cartão Fidelidade</TitleStyleText>
          </Row>

          {!clientDetails.loyalty_card_data ? (
            <Row alignCenter justifyCenter margtop={px2vw(20)} margbottom={px2vw(20)}>
              <TitleStyleText isModalSubText>O cliente não possui dados de cartão fidelidade.</TitleStyleText>
            </Row>
          ) : (
            <Row justifyStart spaceAround margbottom={px2vw(20)}>
              <Col>
                <TitleStyleText isModalSubTitle>Último resgate</TitleStyleText>
                <TitleStyleText isModalSubText>{clientDetails.rescued_loyalty_card_data ? `${formatDateTime(clientDetails.rescued_loyalty_card_data.rescued_at)} | ${formatDiscount(clientDetails.rescued_loyalty_card_data)} de desconto` : '-'}</TitleStyleText>  
              </Col>
              <Col>
                <TitleStyleText isModalSubTitle>Saldo atual</TitleStyleText>
                <Col>
                  <TitleStyleText isModalSmallText>{clientDetails.loyalty_card_data.total_orders_of_card} de {clientDetails.loyalty_card_data.amount_of_purchases} - Vale {formatDiscount(clientDetails.loyalty_card_data)} de desconto</TitleStyleText>
                  <Row margtop={px2vw(5)}>
                    {clientDetails.loyalty_card_data.total_orders_of_card > 0 && (
                      <CompletedBars />
                    )}
                    <EmptyBars />
                  </Row>
                </Col>
              </Col>
              <Col alignEnd justifyEnd>
                <TitleStyleText isModalSubTitle>Validade</TitleStyleText>
                <TitleStyleText isModalSubText>{formatDate(clientDetails.loyalty_card_data.due_at)}</TitleStyleText> 
              </Col>
            </Row>
          )}
        </>
      )}
      <Hr color={cinzaKuppi4} />
      <Row justifyStart margtop={px2vw(10)}>
        <TitleStyleText isModalTitle >Últimos Pedidos</TitleStyleText>
      </Row>
      <ClientDetailsTable data={clientDetails.client_details}></ClientDetailsTable>
    </Modal>
  )
}
