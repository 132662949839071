import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useHotjar from 'react-use-hotjar';
import { toast } from 'react-toastify';

import useSound from 'use-sound';
import newOrderSoundFile from '../../../assets/sounds/new_order_sound.mp3';
import callAttendantSoundFile from '../../../assets/sounds/call_attendant_sound.mp3';
import { Col } from '../../../styles/grid';

import { Wrapper } from './styles';
import history from '../../../services/history';
import { orders } from '../../../routes/routeMap';

import SignatureNotPaidBar from '../../../components/SignatureNotPaidBar';
import SideBar from '../../../components/Signed/SideBar';
import Skel from '../../../components/Signed/Skel';

import RealTimeDimensions from '../../../utils/responsiveness/RealTimeDimensions';
import AccessValidate from '../../../utils/validations/AccessValidate';

import {
  sendWhatsappMessage,
  printAutomaticallyOrderInvoice,
} from '../../../utils/ordersConfigs/ordersConfigs';

import {
  clearCurrOrderStatusData,
  clearCurrOrderConfigs,
  cancelOrderClearSuccess,
  setQzConnected,
} from '../../../store/modules/orders/actions';
import {
  getSubscriptionsRequest,
  getRecurringRequest,
} from '../../../store/modules/profileAccount/actions';
import { getUserRequest } from '../../../store/modules/user/actions';

import { SocketContext, connectWS } from '../../../context/ws';
import qz from 'qz-tray';
import { apiAnunciante } from '../../../services/api';
import { clearCurrManualOrderStatusData } from '../../../store/modules/manualOrder/actions';
import ManualOrderModal from '../../../components/ManualOrderModal';
import px2vw from '../../../utils/responsiveness/px2vw';
import GlobalWppChat from '../../../components/GlobalWhatsappChat';
import wppNewMessageSoundFile from '../../../assets/sounds/wpp_new_message_sound.mp3';
import { newWppMessageNotification } from '../../../store/modules/notifications/actions';
import { WebsocketProvider } from '../../../context/WebSocket';

export default function SignedLayout({ children, option }) {
  const [notificationWS] = useContext(SocketContext);
  const { initHotjar, identifyHotjar } = useHotjar();
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );

  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );

  const kuppiBoost = useSelector((state) => state.kuppiBoost);
  const user = useSelector((state) => state.user.user);
  const newOrdersLength = useSelector((state) => Object.values(state.orders.newAccepted).filter((order) => order.status === 'new').length);
  const currOrderStatusData = useSelector(
    (state) => state.orders.currOrderStatusData
  );
  const currManualOrderStatusData = useSelector(
    (state) => state.manualOrder.currManualOrderStatusData
  );
  const currOrderConfigs = useSelector(
    (state) => state.orders.currOrderConfigs
  );
  const updateOrderStatusSuccess = useSelector(
    (state) => state.orders.updateOrderStatusSuccess
  );
  const createManualOrderSuccess = useSelector(
    (state) => state.manualOrder.createManualOrderSuccess
  );
  const cancelOrderSuccess = useSelector(
    (state) => state.orders.cancelOrderSuccess
  );
  const isDuePayment = useSelector(
    (state) => state.profileAccount.isDuePayment
  );
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const isManualOrderModalVisible = useSelector(
    (state) => state.manualOrder.isManualOrderModalVisible
  );
  const { isNewWppMessagePlaying } = useSelector(
    (state) => state.notifications
  );

  const advertiserUser = useSelector((state) => state.user.user.advertiserInfo);
  const waitAttendantList = useSelector((state) => state.whatsappChat.waitAttendantList);
  const pixKey =
    advertiserUser.advertiserPaymentMethod.find(
      (pm) => pm.payment_method_slug === 'pix_chave'
    ).external_processor_key || null;
  const printMethod = advertiserUser.advertiserOrdersConfig.print_method;
  const printerName = advertiserUser.advertiserPrintConfig.printer_name;
  const maxLineCharsAutomatic =
    advertiserUser.advertiserPrintConfig.max_line_chars_automatic;
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;
  const isWhatsappwebPage = window.location.pathname.includes('whatsappchat');
  const userAccess = useSelector((state) => state.user.access);
  const hasWhatsappchatPermission = AccessValidate(userAccess, null, null, 'whatsapp_bot_chat');
  const dispatch = useDispatch();
  const [
    newOrderSoundPlay,
    { stop: newOrderSoundStop, isPlaying: newOrderSoundIsPlaying },
  ] = useSound(newOrderSoundFile, {
    interrupt: true,
  });
  const [
    callAttendantSoundPlay,
    { stop: callAttendantSoundStop, isPlaying: callAttendantSoundIsPlaying },
  ] = useSound(callAttendantSoundFile, { interrupt: true });
  const [
    wppNewMessageSoundPlay,
    { stop: wppNewMessageSoundStop, isPlaying: wppNewMessageSoundIsPlaying },
  ] = useSound(wppNewMessageSoundFile, { interrupt: true });

  useEffect(() => {
    // If not connected to WS server, connects
    if (!notificationWS.connected) {
      connectWS();
    }

    // Retrieve the advertiser user data, if the user has been blocked
    dispatch(getUserRequest());

    // Retrieve the subscriptions to verify if the signature not paid bar / free trial bar need to be displayed
    dispatch(getSubscriptionsRequest());
    dispatch(getRecurringRequest());
  }, [dispatch]);

  // Hotjar definitions
  useEffect(() => {
    // Define chatbot infos
    const whatsappBotConfigs = advertiserUser.advertiserWhatsappbotConfig;
    const ordersConfigs = advertiserUser.advertiserOrdersConfig;

    const chatBotNeverConnected =
      whatsappBotConfigs.disconnected_at === null &&
      whatsappBotConfigs.connected_at === null;
    let chatBotDisconnected1Day = false;
    let chatBotDisconnected2DaysMore = false;
    let chatBotConnectedInactiveFunctions = false;
    let chatBotConnectedUpdateStatusActive = false;
    let chatBotConnectedChatbotActive = false;
    let chatBotConnectedAll = false;

    // Already connected once
    if (!chatBotNeverConnected) {
      // Connected
      if (
        whatsappBotConfigs.is_active &&
        whatsappBotConfigs.status === 'connected'
      ) {
        const statusActive =
          ordersConfigs.is_send_whatsapp_msg_update_status &&
          ordersConfigs.whatsapp_send_method === 'automatic';
        const chatbotActive = ordersConfigs.is_using_chatbot;
        chatBotConnectedUpdateStatusActive = statusActive && !chatbotActive;
        chatBotConnectedChatbotActive = chatbotActive && !statusActive;
        chatBotConnectedAll = statusActive && chatbotActive;
        chatBotConnectedInactiveFunctions = !statusActive && !chatbotActive;
      }
      // Disconnected
      else {
        const convertMillisecondsInDays = 1000 * 3600 * 24;
        const disconnectedDate = new Date(
          whatsappBotConfigs.disconnected_at
        ).getTime();
        chatBotDisconnected1Day =
          Math.trunc(
            (new Date().getTime() - disconnectedDate) /
              convertMillisecondsInDays
          ) === 1;
        chatBotDisconnected2DaysMore =
          Math.trunc(
            (new Date().getTime() - disconnectedDate) /
              convertMillisecondsInDays
          ) > 1;
      }
    }

    // Define kuppi boost infos
    const hasBalance = kuppiBoost.balance > 0;
    const onboardingNotStarted = kuppiBoost.onboarding.status === 'not_started';
    const onboardingCompleted = kuppiBoost.onboarding.status === 'ok';
    const hasAtLeastOneCampaign = kuppiBoost.campaigns.length > 0;
    const todayDate = new Date();
    const lastMonthDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth() - 1,
      todayDate.getDay()
    );
    const hasAtLeastOneCampaignLastMonth =
      kuppiBoost.campaigns.filter(
        (campaign) =>
          new Date(campaign.finish_at) > lastMonthDate &&
          campaign.status === 'FINISHED'
      ).length > 0;

    let kuppiBoostInitialState = onboardingNotStarted && !hasBalance;
    let kuppiBoostDoOnboarding = onboardingNotStarted && hasBalance;
    let kuppiBoostOnboardingError =
      kuppiBoost.onboarding.status === 'bad_configuration';
    let kuppiBoostInsertFirstBalance =
      onboardingCompleted && !hasBalance && !hasAtLeastOneCampaign;
    let kuppiBoostCreateFirstCampaign =
      onboardingCompleted && hasBalance && !hasAtLeastOneCampaign;
    let kuppiBoostSecondCampaignMore =
      onboardingCompleted &&
      hasAtLeastOneCampaign &&
      !hasAtLeastOneCampaignLastMonth;
    let kuppiBoostActivated =
      onboardingCompleted &&
      hasAtLeastOneCampaign &&
      hasAtLeastOneCampaignLastMonth;

    if (process.env.REACT_APP_HOTJAR_ENABLED === 'true') {
      const hotjarInfoData = {
        name: `${user.advertiserInfo.name}`,
        email: `${user.email}`,
        // NPS group
        nps_survey_group: user.nps_survey_group,
        // MHS group
        mhs_survey_group: user.mhs_survey_group,
        // Other survey
        is_specific_survey_enabled: user.is_specific_survey_enabled,
        // Chatbot infos
        chatbot_never_connected: chatBotNeverConnected,
        chatbot_disconnected_1_day: chatBotDisconnected1Day,
        chatbot_disconnected_2_days_more: chatBotDisconnected2DaysMore,
        chatbot_connected_inactive_functions: chatBotConnectedInactiveFunctions,
        chatbot_connected_update_status_active:
          chatBotConnectedUpdateStatusActive,
        chatbot_connected_chatbot_active: chatBotConnectedChatbotActive,
        chatbot_connected_all: chatBotConnectedAll,
        // Kuppi boost infos
        kuppiboost_initial_state: kuppiBoostInitialState,
        kuppiboost_do_onboarding: kuppiBoostDoOnboarding,
        kuppiboost_onboarding_error: kuppiBoostOnboardingError,
        kuppiboost_insert_first_balance: kuppiBoostInsertFirstBalance,
        kuppiboost_create_first_campaign: kuppiBoostCreateFirstCampaign,
        kuppiboost_second_campaign_more: kuppiBoostSecondCampaignMore,
        kuppiboost_activated: kuppiBoostActivated,
      };

      if (process.env.REACT_APP_ENV_ALL_API === 'production') {
        initHotjar(process.env.REACT_APP_HOTJAR_KEY, 6, false);
        identifyHotjar(`${user.advertiserInfo.id}`, hotjarInfoData);
      } else {
        console.debug('Hotjar - Info that will be sent', hotjarInfoData);
      }
    }
  }, [initHotjar]);

  // Octadesk definitions - login data
  useEffect(() => {
    window.addEventListener('onOctaChatReady', function octadeskLogin(e) {
      window.removeEventListener('onOctaChatReady', octadeskLogin);
      window.octadesk.chat.login({
        user: {
          name: `${user.advertiserInfo.name} - ${user.advertiserInfo.id} (Cliente)`,
          email: `${user.email}`,
        },
      });
    });
  }, []);

  useEffect(() => {
    if (isNewWppMessagePlaying) {
      if (!wppNewMessageSoundIsPlaying) {
        wppNewMessageSoundPlay();
        setTimeout(() => {
          dispatch(newWppMessageNotification(false));
        }, 1000);
      }
    } else {
      wppNewMessageSoundStop();
    }
  }, [isNewWppMessagePlaying]);

  useEffect(() => {
    if (waitAttendantList.length > 0) {
      if (!callAttendantSoundIsPlaying && isWeb) {
        callAttendantSoundPlay();
      }
    } else {
      callAttendantSoundStop();
    }
  }, [callAttendantSoundIsPlaying, waitAttendantList]);

  useEffect(() => {
    // Show notification and sound if has new orders and not playing sound
    if (newOrdersLength > 0) {
      if (!newOrderSoundIsPlaying) {
        showNewOrderNotification();
      }
    } else {
      // Stop sound notification
      newOrderSoundStop();
      // Dismiss new orders toast
      if (toast.isActive('newOrders')) {
        toast.dismiss('newOrders');
      }
    }
  }, [newOrderSoundIsPlaying, newOrdersLength]);

  function showNewOrderNotification() {
    // Play sound notification
    if (!newOrderSoundIsPlaying && isWeb) {
      newOrderSoundPlay();
    }

    // Create browser notification, if the user granted access and was in the web
    if (isWeb && Notification.permission === 'granted') {
      const notification = new Notification('Portal | Kuppi', {
        body: 'Você tem novos pedidos pendentes de aceite! Clique para ir para a plataforma',
        icon: '/notification_kuppi.png',
      });

      // Force click to go back to the plataform
      notification.onclick = () => {
        window.focus();
        history.push(orders);
        notification.close();
      };
    }

    // Show new order toast if not exists any one
    if (!toast.isActive('newOrders')) {
      toast.warn('Você tem novos pedidos!', {
        autoClose: false,
        toastId: 'newOrders',
        onClick: () => history.push(orders),
      });
    }
  }

  useEffect(() => {
    if (updateOrderStatusSuccess || createManualOrderSuccess) {
      // Perform send whatsapp message, if necessary
      sendWhatsappMessage(
        currOrderConfigs.sendWhatsappMessage,
        currOrderStatusData,
        whatsappIsActive,
        pixKey
      );

      // Perform print order invoice, if necessary
      printAutomaticallyOrderInvoice(
        createManualOrderSuccess
          ? alwaysPrintOrder
          : currOrderConfigs.printOrder,
        createManualOrderSuccess
          ? currManualOrderStatusData
          : currOrderStatusData,
        user,
        printMethod,
        printerName,
        maxLineCharsAutomatic,
        createManualOrderSuccess
      );

      dispatch(clearCurrOrderStatusData());
      dispatch(clearCurrManualOrderStatusData());
      dispatch(clearCurrOrderConfigs());
    }
  }, [updateOrderStatusSuccess, createManualOrderSuccess]);

  useEffect(() => {
    if (cancelOrderSuccess) {
      // Perform send whatsapp message, if necessary
      sendWhatsappMessage(
        currOrderConfigs.sendWhatsappMessage,
        {...currOrderStatusData, status: "canceled"},
        whatsappIsActive,
        pixKey
      );

      dispatch(cancelOrderClearSuccess());
      dispatch(clearCurrOrderStatusData());
      dispatch(clearCurrOrderConfigs());
    }
  }, [cancelOrderSuccess]);

  // Connect QZ
  useEffect(() => {
    if (printMethod === 'automatic') {
      qz.security.setCertificatePromise(function (resolve, reject) {
        apiAnunciante
          .get(`/user-info/${user.advertiserInfo.id}/qz-tray/certificate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          apiAnunciante
            .post(`/user-info/${user.advertiserInfo.id}/qz-tray/sign-message`, {
              sign_text: toSign,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
              reject(err);
            });
        };
      });
      if (!qz.websocket.isActive()) {
        qz.websocket
          .connect({ retries: 0 })
          .then(() => {
            toast.info('QZ-Tray conectado!');
            dispatch(setQzConnected(true));
          })
          .catch((e) => {
            dispatch(setQzConnected(false));
          });
      }
    }
  }, [printMethod]);

  return (
    <WebsocketProvider>
      <Col style={{overflowX: 'hidden'}}>
        {isDuePayment && !hasFreeTrialValid && <SignatureNotPaidBar />}
        <Wrapper>
          {/* Manual Order Modal */}
          {isManualOrderModalVisible && !isWhatsappwebPage && (
            <ManualOrderModal
              isVisible={isManualOrderModalVisible}
              width={px2vw(535)}
              height={px2vw(570)}
              padding="0 0"
            />
          )}
          {hasWhatsappchatPermission && !isWhatsappwebPage && whatsappIsActive && <GlobalWppChat />}
          <SideBar option={option} />
          <Skel>{children}</Skel>
        </Wrapper>
      </Col>
    </WebsocketProvider>
  );
}

SignedLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
