import mixpanel from "mixpanel-browser";

const token = process.env.REACT_APP_MIXPANEL_KEY;
const reactAppEnv = process.env.REACT_APP_ENV_ALL_API;

const configs = {
  debug: reactAppEnv !== 'production',
  ignore_dnt: true
}

function initMixPanel(infoId) {
  if (reactAppEnv === 'production') {
    mixpanel.init(token, configs);
    mixpanel.identify(infoId);
  }
}

//only sends data when in production. when using dev or local shows on console data about the batch being sent.
//stg, qa, do not show any info, nor sends any data
function mixPanelTrack(eventName, trackedObject) {
  if(reactAppEnv === 'production') {
    mixpanel.track(`Advertiser Platform Event - ${eventName}`, trackedObject)
  } else if(configs.debug) {
    console.debug(`Advertiser Platform Event - ${eventName}`, trackedObject)
  }
}

export function sendOnboardingInfo(info) {
  initMixPanel(info.id)
  const eventDefaultData = {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    "$currOnboardingStep": info.currOnboardingStep,
    "hasFreeTrialValid": info.hasFreeTrialValid
  }
  let eventData = {}
  switch (info.currOnboardingStep) {
    case '1step':
      eventData = {
        ...eventDefaultData,
        "surveyStep1DataObjective": info.surveyStep1DataObjective
      }
      break;
    case '2step':
      eventData = {
        ...eventDefaultData,
        "surveyStep2DataMktplace": info.surveyStep2DataMktplace,
        "surveyStep2DataHasComputer": info.surveyStep2DataHasComputer
      }
      break;
    case '3step':
      eventData = {
        ...eventDefaultData,
        "surveyStep3DataDeliveryType": info.surveyStep3DataDeliveryType,
        "surveyStep3DataOrdersPerDay": info.surveyStep3DataOrdersPerDay,
        "surveyStep3DataSalesPercentage": info.surveyStep3DataSalesPercentage
      }
      break;
    default:
      eventData = {
        ...eventDefaultData
      }
  }
  mixPanelTrack(`OB-${info.type}-${info.currOnboardingStep}`, eventData)
}

export function sendCreateProfile(info) {
  initMixPanel(info.id)
  if (reactAppEnv === 'production') {
    mixpanel.people.set({
      "$distinct_id": info.id,
      "$name": info.name,
      "$email": info.email,
      "$created": info.created_at,
      "City": info.city,
      "State": info.state,
      "Advertiser Category": info.advertiserCategory,
      "Advertiser Plan Type": info.advertiserPlan,
      "Advertiser Plan Amount": info.advertiserPlanAmount,
      "hasFreeTrialValid": info.hasFreeTrialValid
    })
  } else if (configs.debug) {
    console.debug(`Advertiser Platform Event - Create Profile`, info)
  }
}

export function sendStoreAvailability(info) {
  initMixPanel(info.id)
  mixPanelTrack('Updated store availability configs', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'sunday': info.sunday,
    'monday': info.monday,
    'tuesday': info.tuesday,
    'wednesday': info.wednesday,
    'thursday': info.thursday,
    'friday': info.friday,
    'saturday': info.saturday,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendStoreScheduling(info) {
  initMixPanel(info.id)
  mixPanelTrack('Updated store scheduling availability configs', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'sunday': info.sunday,
    'monday': info.monday,
    'tuesday': info.tuesday,
    'wednesday': info.wednesday,
    'thursday': info.thursday,
    'friday': info.friday,
    'saturday': info.saturday,
    'interval_between_scheduling': info.interval_between_scheduling,
    'max_orders_per_scheduling': info.max_orders_per_scheduling,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendStoreDeliveryTakeAwayConfigs(info) {
  initMixPanel(info.id)
  mixPanelTrack('Updated store delivery_takeaway configs', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'delivery_range_area': info.delivery_range_area,
    'delivery_fee': info.delivery_fee,
    'delivery_dynamic_fee': info.delivery_dynamic_fee,
    'delivery_dynamic_fee_max_dist': info.delivery_dynamic_fee_max_dist,
    'delivery_dynamic_fee_exceeded_amount': info.delivery_dynamic_fee_exceeded_amount,
    'delivery_dynamic_fee_free_dist': info.delivery_dynamic_fee_free_dist,
    'delivery_dynamic_fee_calc_type': info.delivery_dynamic_fee_calc_type,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendReportsData(info) {
  initMixPanel(info.id)
  mixPanelTrack('Accessed “Reports”', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'startDate': info.startDate,
    'endDate': info.endDate,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendProductUpdated(info) {
  initMixPanel(info.id)
  mixPanelTrack('Product Update', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'productId': info.productId,
    'productName': info.productName,
    'productAmount': info.productAmount,
    'sunday': info.sundayJson,
    'monday': info.monday,
    'tuesday': info.tuesday,
    'wednesday': info.wednesday,
    'thursday': info.thursday,
    'friday': info.friday,
    'saturday': info.saturday,
    'segment': info.segment,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendLoyaltyCard(info) {
  initMixPanel(info.id)
  mixPanelTrack('Loyalty Card Update', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'cartDiscountPercentage': info.cartDiscountPercentage,
    'cartDiscountFixed': info.cartDiscountFixed,
    'isActive': info.isActive,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendCouponStatus(info) {
  initMixPanel(info.id)
  mixPanelTrack('Coupon Update', {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'couponId': info.couponId,
    'couponStatus': info.couponStatus,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendCouponInfo(eventName, info) {
  initMixPanel(info.id)
  mixPanelTrack(`Coupon ${eventName}`, {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'couponDisplayName': info.couponDisplayName,
    'couponMode': info.couponMode,
    'cartDiscountPercentage': info.cartDiscountPercentage,
    'promotionalAmount': info.promotionalAmount,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendOrdersFunnel(event, info) {
  initMixPanel(info.id)
  mixPanelTrack(event, {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'Advertiser Id': info.advertiserId,
    'Order id': info.orderId,
    'Order Status': info.status,
    'Created At': info.createdAt,
    'Accepted At': info.acceptedAt,
    'Canceled At': info.canceledAt,
    'Delivery At': info.deliveryAt,
    'Finished At': info.finishedAt,
    'Preparing At': info.preparingAt,
    'Ready Take Away At': info.readyTakeAwayAt,
    'Order Type': info.orderType,
    'Delivery Type': info.deliveryType,
    'Client Id': info.clientId,
    'Client name': info.clientName,
    'Client Email': info.clientEmail,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendManualOrderInfo(info) {
  initMixPanel(info.id)
  mixPanelTrack(`MO-${info.currManualOrderStep}`, {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    "$currManualOrderStep": info.currManualOrderStep,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendSubscriptionPlanRenovation(info) {
  initMixPanel(info.id)
  mixPanelTrack(`Subscription Plan - Renovation`, {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'currentPlanId': info.currentPlanId,
    'currentPlanName': info.currentPlanName,
    'currentPlanAmount': info.currentPlanAmount,
    'currentPlanPeriod': info.currentPlanPeriod,
    'newPlanId': info.newPlanId,
    'newPlanName': info.newPlanName,
    'newPlanAmount': info.newPlanAmount,
    'newPlanPeriod': info.newPlanPeriod,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}

export function sendSubscriptionPlanChangeRenovation(info) {
  initMixPanel(info.id)
  mixPanelTrack(`Subscription Plan Change - Renovation`, {
    '$distinct_id': info.id,
    "$name": info.name,
    "$email": info.email,
    'currentPlanId': info.currentPlanId,
    'currentPlanName': info.currentPlanName,
    'currentPlanAmount': info.currentPlanAmount,
    'currentPlanPeriod': info.currentPlanPeriod,
    'newPlanId': info.newPlanId,
    'newPlanName': info.newPlanName,
    'newPlanAmount': info.newPlanAmount,
    'newPlanPeriod': info.newPlanPeriod,
    "hasFreeTrialValid": info.hasFreeTrialValid
  })
}