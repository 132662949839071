import React, { useState, useEffect } from 'react';
import { Icon } from 'react-materialize';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormControlLabel } from '@material-ui/core/';
import qz from 'qz-tray';

import {
  ModalHeader,
  ModalTitleText,
  ModalText2,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../components/Modal/styles';
import { AcceptingOrdersBox } from '../../components/AcceptingOrdersButtons/styles';
import { Row, Col } from '../../styles/grid';
import {
  SwitchController,
  SwitchButtonBox,
  PageTitle,
  ContentPage,
  LoadingBox,
} from '../_layouts/styles/common';
import {
  OrdersBox,
  SearchInput,
  SearchButton,
  SearchBox,
  SearchImage,
  TabsBox,
  TabBox,
  Tab,
  QtdOrders,
  TabSelected,
  NextStatusIcon,
  ErrorText,
  OrdersConfigsButton,
  ButtonBox,
  OrdersConfigsIcon,
  ButtonText,
  ChoiceButton,
  ChoiceButtonSelected,
  PrintTypeBoxDetail,
  PrintTypeBoxDetailText,
  PrintTypeButtonText,
  PrintTypeInput,
  SelectInput,
  SearchPrinters,
  KnowHowButton,
  QzTrayImageBox,
  QzTrayImage,
  DownloadQzButton,
  DownloadImageBox,
  DownloadImage,
  ObsText,
  QzMaxCharLineText,
} from './styles';
import { preto } from '../../styles/colors';

import OrderDetailModal from '../../components/OrderDetailModal';
import SelectModal from '../../components/SelectModal';
import OrdenationModal from '../../components/OrdenationModal';
import ExportOptionsModal from '../../components/ExportOptionsModal';
import AcceptingOrdersButtons from '../../components/AcceptingOrdersButtons';
import Modal from '../../components/Modal';
import ManualOrderBar from '../../components/ManualOrderBar';
import OrderTable from './OrderTable';

import { onlyNumbers, getOrderRequestStatusByTab } from '../../utils/utils';
import px2vw from '../../utils/responsiveness/px2vw';
import { printInvoiceQZTray } from '../../utils/QzPrint';

import history from '../../services/history';
import { apiAnunciante } from '../../services/api';
import { whatsappbot } from '../../routes/routeMap';
import {
  getOrdersRequest,
  getOrdersClearSuccess,
  changeOrdersConfigsRequest,
  changeOrdersConfigsClearSuccess,
  getSearchedOrderRequest,
  clearSearchedOrderData,
  setQzConnected,
} from '../../store/modules/orders/actions';
import { setManualOrderModalState } from '../../store/modules/manualOrder/actions';

export default function Orders() {
  const user = useSelector((state) => state.user.user);
  const currSearchedOrderData = useSelector(
    (state) => state.orders.currSearchedOrderData
  );
  const ordersQty = useSelector((state) => state.orders.ordersQty);
  const deliveryActive = useSelector(
    (state) => state.user.user.advertiserInfo.is_using_delivery
  );
  const takeAwayActive = useSelector(
    (state) => state.user.user.advertiserInfo.is_using_take_away
  );
  const [selectedTab, setSelectedTab] = useState('tabNew');
  const [configModalSelectedTab, setConfigModalSelectedTab] = useState('print');
  const [ordersConfigsModal, setOrdersConfigsModal] = useState(false);
  const [openQzDownload, setOpenQzDownload] = useState(false);
  const printType = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPrintConfig.print_type
  );
  const [selectedPrintType, setSelectedPrintType] = useState(printType);
  const maxCharLine = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserPrintConfig.max_line_chars
  );
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );
  const [selectedMaxCharLine, setSelectedMaxCharLine] = useState(maxCharLine);
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const printMethod = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig.print_method
  );
  const printerName = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPrintConfig.printer_name
  );
  const maxLineCharsAutomatic = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserPrintConfig
        .max_line_chars_automatic
  );
  const [printers, setPrinters] = useState([
    {
      id: 'select_printer',
      title: 'Selecione a impressora',
    },
  ]);
  const [selectedAlwaysPrintOrder, setSelectedAlwaysPrintOrder] =
    useState(alwaysPrintOrder);
  const [selectedPrintOrderManual, setSelectedPrintOrderManual] =
    useState(false);
  const [selectedPrintOrderAutomatic, setSelectedPrintOrderAutomatic] =
    useState(false);
  const [selectedPrintMethod, setSelectedPrintMethod] = useState(printMethod);
  const [selectedPrinterName, setSelectedPrinterName] = useState(printerName);
  const [selectedMaxCharLineAutomatic, setSelectedMaxCharLineAutomatic] =
    useState(maxLineCharsAutomatic);

  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const isQzConnected = useSelector((state) => state.orders.isQzConnected);

  const [selectedAlwaysSendWhatsappMsg, setSelectedAlwaysSendWhatsappMsg] =
    useState(alwaysSendWhatsappMsg && whatsappIsActive);

  const [orderData, setOrderData] = useState([]);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderNumberSearchInputValue, setOrderNumberSearchInputValue] =
    useState('');
  const getSearchedOrderLoading = useSelector(
    (state) => state.orders.getSearchedOrderLoading
  );
  const getOrdersLoading = useSelector(
    (state) => state.orders.getOrdersLoading
  );
  const getOrdersSuccess = useSelector(
    (state) => state.orders.getOrdersSuccess
  );
  const error = useSelector((state) => state.orders.error);
  const changeOrdersConfigsLoading = useSelector(
    (state) => state.orders.changeOrdersConfigsLoading
  );
  const changeOrdersConfigsSuccess = useSelector(
    (state) => state.orders.changeOrdersConfigsSuccess
  );
  const isUsingChatbot = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig.is_using_chatbot
  );

  // Find QZ Tray available printers
  function findPrinters() {
    qz.printers
      .find()
      .then((data) => {
        const list = [];
        list.push({
          id: 'select_printer',
          title: 'Selecione a impressora',
        });

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            list.push({
              id: data[i],
              title: data[i],
            });
          }
        } else {
          toast.warning(`Nenhuma impressora localizada`);
        }

        setPrinters(list);
      })
      .catch((e) => {
        toast.error(
          `Erro ao buscar impressora! Por gentileza, baixe o programa QZ-Tray ou ative-o caso ja tenha baixado.`
        );
        handlePrintMethod('manual');
      });
  }

  // Print test data
  function printTest() {
    printInvoiceQZTray(
      {
        printerName: selectedPrinterName,
        orderStatusData: null,
        advertiserData: user,
        maxLineCharsAutomatic: selectedMaxCharLineAutomatic,
      },
      true
    );
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve the new and accepted order list
    dispatch(getOrdersRequest('new_accepted'));
  }, [dispatch]);

  useEffect(() => {
    if (selectedPrintMethod === 'automatic') {
      qz.security.setCertificatePromise(function (resolve, reject) {
        apiAnunciante
          .get(`/user-info/${user.advertiserInfo.id}/qz-tray/certificate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          apiAnunciante
            .post(`/user-info/${user.advertiserInfo.id}/qz-tray/sign-message`, {
              sign_text: toSign,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
              reject(err);
            });
        };
      });

      // Force connect when qz isn't connected
      if (!qz.websocket.isActive()) {
        qz.websocket
          .connect({ retries: 0 })
          .then(() => {
            toast.info('QZ-Tray conectado!');
            dispatch(setQzConnected(true));
            findPrinters();
          })
          .catch((e) => {
            toast.warning(
              'Falha ao conectar QZ-Tray, por gentileza baixe o programa ou ative-o caso ja tenha baixado'
            );
            dispatch(setQzConnected(false));
          });
      }
    }
  }, [selectedPrintMethod]);

  function handleTabSelected(newTabClick) {
    setSelectedTab(newTabClick);
    dispatch(getOrdersRequest(getOrderRequestStatusByTab(newTabClick)));
  }

  function handleConfigModalTabSelected(newTabClick) {
    setConfigModalSelectedTab(newTabClick);
  }

  function handlePrintMethod(method) {
    if (method === 'manual') {
      setSelectedPrintMethod(method);
      setSelectedPrintOrderManual(true);
      setSelectedPrintOrderAutomatic(false);
    } else if (method === 'automatic') {
      setSelectedPrintMethod(method);
      setSelectedPrintOrderManual(false);
      setSelectedPrintOrderAutomatic(true);
    }
  }

  function handleQueryOrderDetailModalResponse() {
    if (currSearchedOrderData) {
      dispatch(clearSearchedOrderData());
    }
    setOrderDetailModal(false);
    setOrderData([]);
    setSelectedAlwaysSendWhatsappMsg(alwaysSendWhatsappMsg && whatsappIsActive);
    setSelectedAlwaysPrintOrder(alwaysPrintOrder);
  }

  function handleQueryOrderNumber() {
    if (orderNumberSearchInputValue !== '') {
      dispatch(getSearchedOrderRequest(orderNumberSearchInputValue));
    } else {
      toast.info('Informe o número do pedido a ser pesquisado.', {
        autoClose: 6000,
      });
    }
  }

  function handleChangeOrdersConfig() {
    if (selectedPrintType === 'plain_text' && selectedMaxCharLine === '') {
      toast.error(
        'Para o tipo de impressão "Plain Text", o campo de quant. máx. de colunas por linha deve ser preenchido. O valor recomendado para a maioria das impressoras é 47.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      selectedPrintType === 'plain_text' &&
      Number(selectedMaxCharLine) === Number(0)
    ) {
      toast.error(
        'Para o tipo de impressão "Plain Text", o campo de quant. máx. de colunas por linha não pode ser zero. O valor recomendado para a maioria das impressoras é 47.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      selectedPrintType === 'plain_text' &&
      Number(selectedMaxCharLine) < Number(36)
    ) {
      toast.error(
        'Para o tipo de impressão "Plain Text", o campo de quant. máx. de colunas por linha não pode ser menor que 36. O valor recomendado para a maioria das impressoras é 47.',
        {
          autoClose: 6000,
        }
      );
    } else if (selectedPrintMethod === 'automatic' && !isQzConnected) {
      toast.error(
        'Falha ao conectar QZ-Tray, por gentileza baixe o programa ou ative-o caso ja tenha baixado',
        {
          autoClose: 6000,
        }
      );
    } else if (
      selectedMaxCharLineAutomatic === null ||
      selectedMaxCharLineAutomatic === 0
    ) {
      toast.error(
        'Por gentileza selecionar um valor de caracteres válido pra impressão!',
        {
          autoClose: 6000,
        }
      );
    } else if (selectedMaxCharLineAutomatic < 32) {
      toast.error('O Numero de Caracteres não pode ser menor que 32', {
        autoClose: 6000,
      });
    } else if (selectedMaxCharLineAutomatic > 48) {
      toast.error('O Numero de Caracteres não pode ser maior que 48', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        changeOrdersConfigsRequest(
          selectedPrintType,
          selectedMaxCharLine,
          selectedAlwaysSendWhatsappMsg,
          selectedAlwaysPrintOrder,
          isUsingChatbot,
          selectedPrintMethod,
          selectedPrinterName,
          selectedMaxCharLineAutomatic
        )
      );
    }
  }

  function handleAlwaysSendWhatsappMsg() {
    if (!whatsappIsActive && !selectedAlwaysSendWhatsappMsg) {
      toast.warn(
        'Para ativar o envio de mensagem automática, deve-se conectar o seu Whatsapp a nossa plataforma. Clique aqui para ir a tela de ativação.',
        {
          autoClose: 9000,
          onClick: () => history.push(whatsappbot),
        }
      );

      setSelectedAlwaysSendWhatsappMsg(false);
      return;
    }

    setSelectedAlwaysSendWhatsappMsg(!selectedAlwaysSendWhatsappMsg);
  }

  function handleCloseOrderConfigsModal() {
    setConfigModalSelectedTab('print');
    setOrdersConfigsModal(false);
  }

  useEffect(() => {
    if (getOrdersSuccess) {
      dispatch(getOrdersClearSuccess());
    }
  }, [getOrdersSuccess, dispatch]);

  useEffect(() => {
    if (changeOrdersConfigsSuccess) {
      dispatch(changeOrdersConfigsClearSuccess());
      handleCloseOrderConfigsModal();
    }
  }, [changeOrdersConfigsSuccess, dispatch]);

  useEffect(() => {
    setSelectedAlwaysPrintOrder(alwaysPrintOrder);
    setSelectedAlwaysSendWhatsappMsg(alwaysSendWhatsappMsg && whatsappIsActive);
    setSelectedPrinterName(printerName);
    setSelectedMaxCharLineAutomatic(maxLineCharsAutomatic);
  }, [
    alwaysPrintOrder,
    alwaysSendWhatsappMsg,
    printerName,
    maxLineCharsAutomatic,
  ]);

  useEffect(() => {
    setSelectedPrintMethod(printMethod);
    if (printMethod === 'manual') {
      setSelectedPrintOrderManual(true);
      setSelectedPrintOrderAutomatic(false);
    } else if (printMethod === 'automatic') {
      setSelectedPrintOrderManual(false);
      setSelectedPrintOrderAutomatic(true);
    }
  }, [printMethod]);

  useEffect(() => {
    if (currSearchedOrderData) {
      setOrderData(currSearchedOrderData);
      setOrderDetailModal(true);
      setOrderNumberSearchInputValue('');
    }
  }, [currSearchedOrderData]);

  const contentPage = (
    <>
      <ContentPage>
        <Row height="auto" margbottom={px2vw(20)}>
          <PageTitle>Pedidos</PageTitle>
          <Col alignEnd margtop={px2vw(-30)}>
            <AcceptingOrdersBox>
              <AcceptingOrdersButtons />
            </AcceptingOrdersBox>
          </Col>
        </Row>
        <Row spaceBetween margbottom={px2vw(15)} height="auto">
          <ManualOrderBar
            onClick={() => dispatch(setManualOrderModalState(true))}
          />
          <Row width="auto">
            <OrdersConfigsButton
              onClick={() => {
                setSelectedPrintType(printType);
                setSelectedMaxCharLine(maxCharLine);
                setSelectedAlwaysPrintOrder(alwaysPrintOrder);
                setSelectedAlwaysSendWhatsappMsg(alwaysSendWhatsappMsg && whatsappIsActive);
                if (selectedPrintMethod === 'automatic') {
                  findPrinters();
                }
                // Open print config modal
                setOrdersConfigsModal(true);
              }}
            >
              <ButtonBox>
                <OrdersConfigsIcon />
                <ButtonText>Configurações</ButtonText>
              </ButtonBox>
            </OrdersConfigsButton>
            {false && (
              <ExportOptionsModal
                primaryOption="Exportar pedidos"
                options={[
                  { id: 'today', title: 'De Hoje' },
                  { id: 'thisWeek', title: 'Desta semana' },
                  { id: 'thisMonth', title: 'Deste mês' },
                ]}
              />
            )}
            <SearchBox>
              <SearchInput
                maxLength="10"
                placeholder="Busque por um pedido"
                value={orderNumberSearchInputValue}
                onChange={(t) =>
                  setOrderNumberSearchInputValue(onlyNumbers(t.target.value))
                }
              />
              <SearchButton
                onClick={() =>
                  !getSearchedOrderLoading && handleQueryOrderNumber()
                }
              >
                {getSearchedOrderLoading ? (
                  <Spinner
                    style={{
                      width: '1.6rem',
                      height: '1.6rem',
                      color: preto,
                    }}
                  />
                ) : (
                  <SearchImage
                    src={require('../../assets/images/search/search.png')}
                  />
                )}
              </SearchButton>
            </SearchBox>
          </Row>
        </Row>
        <OrdersBox>
          <TabsBox>
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabNew')}>Pendentes</Tab>
              {selectedTab === 'tabNew' && (
                <TabSelected
                  isVisible={selectedTab === 'tabNew'}
                  width="auto"
                />
              )}
            </TabBox>
            {ordersQty.totalNewAcceptedOrdersQty > 0 && (
              <QtdOrders isVisible={ordersQty.totalNewAcceptedOrdersQty > 0}>
                {ordersQty.totalNewAcceptedOrdersQty}
              </QtdOrders>
            )}
            <NextStatusIcon />
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabPreparing')}>
                Em preparo
              </Tab>
              {selectedTab === 'tabPreparing' && (
                <TabSelected
                  isVisible={selectedTab === 'tabPreparing'}
                  width="auto"
                />
              )}
            </TabBox>
            {ordersQty.totalPreparingOrdersQty > 0 && (
              <QtdOrders isVisible={ordersQty.totalPreparingOrdersQty > 0}>
                {ordersQty.totalPreparingOrdersQty}
              </QtdOrders>
            )}
            <NextStatusIcon />
            <TabBox>
              <Tab
                onClick={() => handleTabSelected('tabDeliveringReadyTakeAway')}
              >
                {deliveryActive &&
                  takeAwayActive &&
                  `Em entrega / Pronto para retirada`}
                {deliveryActive && !takeAwayActive && `Em entrega`}
                {!deliveryActive && takeAwayActive && `Pronto para retirada`}
              </Tab>
              {selectedTab === 'tabDeliveringReadyTakeAway' && (
                <TabSelected
                  isVisible={selectedTab === 'tabDeliveringReadyTakeAway'}
                  width="auto"
                />
              )}
            </TabBox>
            {ordersQty.totalReadyTakeAwayOrdersQty > 0 && (
              <QtdOrders isVisible={ordersQty.totalReadyTakeAwayOrdersQty > 0}>
                {ordersQty.totalReadyTakeAwayOrdersQty}
              </QtdOrders>
            )}
            <NextStatusIcon />
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabFinished')}>
                Concluídos
              </Tab>
              {selectedTab === 'tabFinished' && (
                <TabSelected
                  isVisible={selectedTab === 'tabFinished'}
                  width="auto"
                />
              )}
            </TabBox>
            {false && (
              <Row width="450px">
                <SelectModal
                  isHidden={!selectedTab === 'tabFinished'}
                  className="ml-auto"
                  margtop="35px"
                  onSelectOption={(selectedOption) => {}}
                  selectedOption="Hoje"
                  options={[
                    { id: 'today', title: 'Hoje' },
                    { id: 'thisWeek', title: 'Essa semana' },
                    { id: 'thisMonth', title: 'Esse mês' },
                    { id: 'sinceBegin', title: 'Desde o início' },
                  ]}
                />
                <OrdenationModal
                  className="ml-auto"
                  onSelectOption={(selectedOption) => {}}
                  selectedOption="Mais novos primeiros"
                  options={[
                    { id: 'newerFirst', title: 'Mais novos primeiros' },
                    { id: 'oldestFirst', title: 'Mais antigos primeiros' },
                  ]}
                />
              </Row>
            )}
          </TabsBox>
          {getOrdersLoading && (
            <Col height="20vh">
              <Col alignCenter justifyCenter>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />
              </Col>
            </Col>
          )}
          {!getOrdersLoading && (
            <OrderTable
              selectedTab={selectedTab}
            />
          )}
        </OrdersBox>
      </ContentPage>
      {/* Order Detail Modal */}
      {orderDetailModal && (
        <OrderDetailModal
          isVisible={orderDetailModal}
          orders={[...orderData]}
          orderIndex={0}
          hasNavigation={false}
          onModalResponse={() => handleQueryOrderDetailModalResponse()}
          onScreen="pedidos"
          selectedPrinterName={selectedPrinterName}
          selectedMaxCharLineAutomatic={selectedMaxCharLineAutomatic}
        />
      )}

      {/* Orders Config Modal */}
      {ordersConfigsModal && (
        <Modal
          isVisible={ordersConfigsModal}
          width={px2vw(500)}
          height={px2vw(575)}
          padding={px2vw(30)}
        >
          <ModalHeader>
            <ModalTitleText>Configurações</ModalTitleText>
            <CloseButton
              disabled={changeOrdersConfigsLoading}
              onClick={() => handleCloseOrderConfigsModal()}
            >
              <CloseImage
                src={require('../../assets/images/close/close.png')}
              />
            </CloseButton>
          </ModalHeader>

          <Col width="auto" height="auto" margtop={px2vw(10)}>
            {/* Always send message*/}
            <Row
              cursor="pointer"
              width="auto"
              height="auto"
              margtop={px2vw(10)}
              onClick={() => handleAlwaysSendWhatsappMsg()}
              disabled={changeOrdersConfigsLoading}
            >
              <Row relative width="auto" height="auto">
                <ChoiceButton />
                <ChoiceButtonSelected
                  isChecked={selectedAlwaysSendWhatsappMsg}
                />
              </Row>
              <Col margtop={px2vw(-3)} width="auto" height="auto">
                <PrintTypeButtonText>
                  Sempre enviar mensagem sobre o status do pedido no whatsapp,
                  ao aceitar o pedido e sair para entrega/ficar pronto para
                  retirada
                </PrintTypeButtonText>
              </Col>
            </Row>
            {/* Always print */}
            <Row
              cursor="pointer"
              width="auto"
              height="auto"
              alignCenter
              margtop={px2vw(8)}
              onClick={() =>
                setSelectedAlwaysPrintOrder(!selectedAlwaysPrintOrder)
              }
              disabled={changeOrdersConfigsLoading}
            >
              <Row relative width="auto" height="auto">
                <ChoiceButton />
                <ChoiceButtonSelected isChecked={selectedAlwaysPrintOrder} />
              </Row>
              <Col width="auto" height="auto">
                <PrintTypeButtonText>
                  Sempre imprimir o pedido ao aceitá-lo
                </PrintTypeButtonText>
              </Col>
            </Row>
          </Col>

          {/* Tabs */}
          <Row height="auto" justifyStart margtop={px2vw(10)}>
            <TabsBox borderbot="none">
              <TabBox margright={px2vw(30)}>
                <Tab onClick={() => handleConfigModalTabSelected('print')}>
                  Impressão
                </Tab>
                {configModalSelectedTab === 'print' && (
                  <TabSelected
                    isVisible={configModalSelectedTab === 'print'}
                    width="auto"
                  />
                )}
              </TabBox>
            </TabsBox>
          </Row>

          <Col margtop={px2vw(5)}>
            {configModalSelectedTab === 'print' && (
              <>
                <Col margtop={px2vw(-10)} width="auto" height="auto">
                  {/* Manual */}
                  <SwitchButtonBox margtop={px2vw(10)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={selectedPrintOrderManual}
                          onChange={() => handlePrintMethod('manual')}
                        />
                      }
                      label={
                        <PrintTypeButtonText>
                          Impressão manual
                        </PrintTypeButtonText>
                      }
                    />
                  </SwitchButtonBox>

                  {/* Automatic */}
                  <Row width="auto" height="auto">
                    <SwitchButtonBox>
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={selectedPrintOrderAutomatic}
                            onChange={() => handlePrintMethod('automatic')}
                          />
                        }
                        label={
                          <PrintTypeButtonText>
                            Impressão automática
                          </PrintTypeButtonText>
                        }
                      />
                    </SwitchButtonBox>
                    <Col
                      width="auto"
                      height="auto"
                      margleft={px2vw(20)}
                      margtop={px2vw(-5)}
                    >
                      <KnowHowButton
                        onClick={() => {
                          setOpenQzDownload(true);
                        }}
                      >
                        Saiba Como
                      </KnowHowButton>
                    </Col>
                  </Row>
                </Col>
                {/* Select HTML or Plain Text */}
                {selectedPrintOrderManual && (
                  <Col margtop={px2vw(10)} height="auto">
                    <ModalText2 fontsize={px2vw(14)}>Formatação</ModalText2>
                    <Row justifyStart margtop={px2vw(8)}>
                      <Row
                        cursor="pointer"
                        width="auto"
                        height="auto"
                        onClick={() => setSelectedPrintType('html')}
                        disabled={changeOrdersConfigsLoading}
                      >
                        <Row relative width="auto" height="auto">
                          <ChoiceButton />
                          <ChoiceButtonSelected
                            isChecked={selectedPrintType === 'html'}
                          />
                        </Row>
                        <Col margtop={px2vw(-3)} width="auto" height="auto">
                          <PrintTypeButtonText>HTML</PrintTypeButtonText>
                        </Col>
                      </Row>
                      <Row
                        cursor="pointer"
                        width="auto"
                        height="auto"
                        margleft={px2vw(25)}
                        onClick={() => setSelectedPrintType('plain_text')}
                        disabled={changeOrdersConfigsLoading}
                      >
                        <Row relative width="auto" height="auto">
                          <ChoiceButton />
                          <ChoiceButtonSelected
                            isChecked={selectedPrintType === 'plain_text'}
                          />
                        </Row>
                        <Col margtop={px2vw(-3)} width="auto" height="auto">
                          <PrintTypeButtonText>Plain Text</PrintTypeButtonText>
                        </Col>
                      </Row>
                    </Row>
                    <Row justifyStart height={px2vw(25)} minheight={px2vw(25)}>
                      {selectedPrintType === 'plain_text' && (
                        <>
                          <PrintTypeButtonText margtop={px2vw(16)}>
                            Quant. máx. de colunas por linha:
                          </PrintTypeButtonText>
                          <PrintTypeInput
                            name="qtdMaxCharLine"
                            maxLength="2"
                            value={selectedMaxCharLine}
                            placeholder="47"
                            onChange={(t) =>
                              setSelectedMaxCharLine(
                                onlyNumbers(t.target.value)
                              )
                            }
                          />
                        </>
                      )}
                    </Row>
                    <PrintTypeBoxDetail>
                      {selectedPrintType === 'html' && (
                        <PrintTypeBoxDetailText>
                          A impressão em HTML, vai fazer a sua notinha ficar
                          mais bonita, com textos em negrito (para dar
                          destaque), tamanhos diferenciados de fontes, etc.
                          Porém nem sempre as impressoras entendem esse formato
                          e acabam imprimindo todas as informações de uma forma
                          bem confusa. Caso isso ocorra, escolha o tipo de
                          impressão "Plain Text".
                        </PrintTypeBoxDetailText>
                      )}
                      {selectedPrintType === 'plain_text' && (
                        <PrintTypeBoxDetailText>
                          A impressão em Plain Text, não tem nenhum tipo de
                          estilização, porém os dados são formatos da melhor
                          forma, afim de que a notinha seja impressa com todas
                          as informações visíveis para o usuário. Algumas
                          impressoras somente permitem esse tipo de impressão.
                        </PrintTypeBoxDetailText>
                      )}
                    </PrintTypeBoxDetail>
                    <Col height="auto">
                      <ModalActions width="100%" justifyContent="space-between">
                        <ModalSecundaryButton
                          margtop={px2vw(30)}
                          width={px2vw(170)}
                          disabled={changeOrdersConfigsLoading}
                          type="button"
                          onClick={() => handleCloseOrderConfigsModal()}
                        >
                          <ModalSecundaryButtonText>
                            Cancelar
                          </ModalSecundaryButtonText>
                        </ModalSecundaryButton>
                        <ModalPrimaryButton
                          margtop={px2vw(30)}
                          width={px2vw(170)}
                          disabled={changeOrdersConfigsLoading}
                          type="button"
                          onClick={() => {
                            // Change print config data
                            handleChangeOrdersConfig();
                          }}
                        >
                          {changeOrdersConfigsLoading ? (
                            <LoadingBox>
                              <Spinner
                                style={{
                                  width: '1.8rem',
                                  height: '1.8rem',
                                  color: preto,
                                }}
                              />{' '}
                            </LoadingBox>
                          ) : (
                            <ModalPrimaryButtonText>
                              Salvar
                            </ModalPrimaryButtonText>
                          )}
                        </ModalPrimaryButton>
                      </ModalActions>
                    </Col>
                  </Col>
                )}

                {/* QZ-Tray */}
                {selectedPrintOrderAutomatic && (
                  <>
                    <Col alignCenter margbottom={px2vw(10)} margtop={px2vw(10)}>
                      <SelectInput
                        name="editPrinter"
                        width={px2vw(350)}
                        height={px2vw(30)}
                        value={selectedPrinterName}
                        placeholder="Selecione a impressora"
                        options={printers}
                        onChange={(e) => {
                          setSelectedPrinterName(e.target.value);
                        }}
                      />

                      <Row margtop={px2vw(10)} alignCenter>
                        <Col height="auto" width="auto">
                          <QzMaxCharLineText>
                            {'Quantidade máxima de caracteres por linha:'}
                          </QzMaxCharLineText>
                        </Col>
                        <Col height="auto" width="auto" margtop={px2vw(-10)}>
                          <PrintTypeInput
                            name="maxCharLine"
                            type="text"
                            value={selectedMaxCharLineAutomatic}
                            onChange={(e) => {
                              setSelectedMaxCharLineAutomatic(
                                Number(e.target.value)
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <PrintTypeBoxDetail height={px2vw(50)}>
                        <PrintTypeBoxDetailText>
                          Ajuste entre 32 e 48 caracteres por linha, para que se
                          adeque ao que sua impressora imprime.
                        </PrintTypeBoxDetailText>
                      </PrintTypeBoxDetail>

                      <Row width={px2vw(350)} margtop={px2vw(10)} spaceBetween>
                        <SearchPrinters onClick={findPrinters}>
                          Buscar impressoras
                        </SearchPrinters>
                        <SearchPrinters onClick={printTest}>
                          Imprimir teste
                        </SearchPrinters>
                      </Row>
                    </Col>

                    <Col height="auto" margtop={px2vw(3)}>
                      <ModalActions width="100%" justifyContent="space-between">
                        <ModalSecundaryButton
                          margtop={px2vw(30)}
                          width={px2vw(170)}
                          disabled={changeOrdersConfigsLoading}
                          type="button"
                          onClick={() => handleCloseOrderConfigsModal()}
                        >
                          <ModalSecundaryButtonText>
                            Cancelar
                          </ModalSecundaryButtonText>
                        </ModalSecundaryButton>
                        <ModalPrimaryButton
                          margtop={px2vw(30)}
                          width={px2vw(170)}
                          disabled={changeOrdersConfigsLoading}
                          type="button"
                          onClick={() => {
                            // Change print config data
                            handleChangeOrdersConfig();
                          }}
                        >
                          {changeOrdersConfigsLoading ? (
                            <LoadingBox>
                              <Spinner
                                style={{
                                  width: '1.8rem',
                                  height: '1.8rem',
                                  color: preto,
                                }}
                              />{' '}
                            </LoadingBox>
                          ) : (
                            <ModalPrimaryButtonText>
                              Salvar
                            </ModalPrimaryButtonText>
                          )}
                        </ModalPrimaryButton>
                      </ModalActions>
                    </Col>
                  </>
                )}
              </>
            )}
          </Col>
        </Modal>
      )}

      {/* QZ-Tray Download Modal */}
      {openQzDownload && (
        <Modal
          isVisible={ordersConfigsModal}
          width={px2vw(500)}
          height={px2vw(100)}
          padding={px2vw(30)}
        >
          <Col alignStart justifyStart>
            <ModalHeader>
              <Row justifyCenter height="auto">
                <ModalTitleText>Impressão Automática</ModalTitleText>
              </Row>
              <CloseButton
                disabled={changeOrdersConfigsLoading}
                onClick={() => {
                  setOpenQzDownload(false);
                }}
              >
                <CloseImage
                  src={require('../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <Col height="auto" margtop={px2vw(20)}>
              <PrintTypeBoxDetailText textalign="center">
                Para imprimir o pedido automaticamente sempre que aceitá-lo é
                bem tranquilo. Basta baixar o programa QZ-Tray e instalá-lo no
                seu computador.
              </PrintTypeBoxDetailText>
            </Col>
            <Row margtop={px2vw(20)} margbottom={px2vw(20)}>
              <QzTrayImageBox>
                <QzTrayImage
                  src={require('../../assets/images/orders/qz_image@2x.png')}
                />
              </QzTrayImageBox>
              <DownloadQzButton href="https://github.com/qzind/tray/releases/download/v2.1.6/qz-tray-2.1.6+3.exe">
                Baixar Programa QZ-Tray
                <DownloadImageBox>
                  <DownloadImage
                    src={require('../../assets/images/download_image/download_image@2x.png')}
                  />
                </DownloadImageBox>
              </DownloadQzButton>
            </Row>
            <Row margbottom={px2vw(20)} margleft={px2vw(40)} width={px2vw(350)}>
              <PrintTypeBoxDetailText textalign="center">
                <Col alignCenter>
                  <Row margbotton={px2vw(10)}>
                    Caso você tenha algum problema na instalação por conta do
                    Java, esse é o link certo para instalação:
                  </Row>
                  <a
                    href="https://www.java.com/pt-BR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.java.com/pt-BR
                  </a>
                </Col>
              </PrintTypeBoxDetailText>
            </Row>
            <ObsText textalign="center">
              OBS: Se o programa já foi instalado, basta ativá-lo.
            </ObsText>
          </Col>
        </Modal>
      )}
    </>
  );

  const errorPage = (
    <ContentPage>
      <Col margleft={px2vw(20)} height="80vh">
        <Row alignCenter justifyCenter>
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os pedidos. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (error) {
    return errorPage;
  }
  return contentPage;
}
