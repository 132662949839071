import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'react-materialize';
import moment from 'moment';

import { Row, Col } from '../../../styles/grid';
import { ErrorText, DetailsButton } from '../styles';
import { Header, HeaderButton, Hr, NextIcon, PagButton, PrevIcon, TableRow } from '../ClientsTable/styles';
import { Content } from './styles';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';

import OrderDetailModal from '../../../components/OrderDetailModal';
import TablePerPage from '../../../components/TablePerPage';
import { Arrow, Arrow2, SvgBox } from '../ClientsTable/icons';
import { makePages, sort } from '../ClientsTable/tableOptions';

import { clearSearchedOrderData, getClientOrdersData } from '../../../store/modules/orders/actions';

export default function ClientDetailsTable({ data = [] }) {
  const dispatch = useDispatch();
  const { currSearchedOrderData } = useSelector((state) => state.orders);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function formatDateTimeLastOrder(lastOrderDateTime) {
    const lastOrderDate = moment(
      lastOrderDateTime,
      'DD/MM/YYYY HH:mm:ss',
      true
    ).format('DD/MM/YYYY');
    const currentDate = moment(new Date()).format('DD/MM/YYYY');

    if (lastOrderDate === currentDate) {
      return `Hoje às ${moment(
        lastOrderDateTime,
        'DD/MM/YYYY HH:mm:ss',
        true
      ).format('HH:mm')}`;
    }

    return moment(lastOrderDateTime, 'DD/MM/YYYY HH:mm:ss', true).format(
      'DD/MM/YYYY à\\s HH:mm'
    );
  }

  const handleGetOrderDetails = (orderId) => {
    dispatch(getClientOrdersData([orderId]));
    setOrderDetailModal(true);
  };

  const handleCloseOrderDetails = () => {
    dispatch(clearSearchedOrderData());
    setOrderDetailModal(false);
  };

  return (
    <>
      <Col padding={`0 ${px2vw(15)}`} alignCenter justifyCenter>
        <Row margbottom={px2vw(-15)}>
          <Header onClick={() => handleSort('id')} width="18%" >
            <HeaderButton>
              # Pedido
              <SvgBox sorted={sortedCol === 'id'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header onClick={() => handleSort('created_at')} width="21%" >
            <HeaderButton>
              Data/hora da<br />última compra
              <SvgBox sorted={sortedCol === 'created_at'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header onClick={() => handleSort('total_amount')} width="17%" >
            <HeaderButton>
              Total
              <SvgBox sorted={sortedCol === 'total_amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header width="53%" >
            <HeaderButton>
              Endereço
            </HeaderButton>
          </Header>
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((client, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={`client-${index}`}>
                <TableRow alignCenter justifyCenter>
                  <Content width="25%" height={px2vw(40)}>
                    {client.id}
                  </Content>
                  <Content width="28%" height={px2vw(40)}>
                    {formatDateTimeLastOrder(client.created_at)}
                  </Content>
                  <Content width="20%" height={px2vw(40)} margleft={px2vw(10)}>
                    {formatCurrencyBRL(client.total_amount, 2)}
                  </Content>
                  <Content center width="75%" height={px2vw(40)} margleft={px2vw(10)}>
                    {client.address}
                  </Content>
                  <Content width="10%">
                    <DetailsButton 
                      onClick={() => handleGetOrderDetails(client.id)}
                    >
                      Detalhes
                    </DetailsButton>
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Col height="20vh">
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>Você não tem clientes a serem exibidos.</ErrorText>
            </Row>
          </Col>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
              <TablePerPage response={handlePerPage} length={rows.length} />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>

      {/* Order detail modal */}
      {orderDetailModal && (
        <OrderDetailModal
          isVisible={orderDetailModal}
          orders={currSearchedOrderData || []}
          hasNavigation={currSearchedOrderData?.length > 0}
          orderIndex={0}
          onModalResponse={handleCloseOrderDetails}
          onScreen="clients"
        />
      )}
    </>
  );
}
