import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import px2vw from '../../utils/responsiveness/px2vw';

import { LoadingBox } from '../../pages/_layouts/styles/common';

import { preto } from '../../styles/colors';

import Modal from '../Modal';

import {
  ModalActions,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../Modal/styles';

import {
  ConfirmPreparingSchedulingOrderModal,
  ConfirmPreparingSchedulingOrderText,
} from './styles';

import { updateOrderStatusRequest } from '../../store/modules/orders/actions';

export default function ConfirmPreparingSchedulingOrder(props) {
  const { isVisible, orders, orderIndex, onModalResponse } = props;
  const [visible, setVisible] = useState(isVisible);
  const [ordersStatusesData, setOrdersStatusesData] = useState(orders);
  const [orderStatusDataIndex, setOrderStatusDataIndex] = useState(orderIndex);
  const updateOrderStatusLoading = useSelector(
    (state) => state.orders.updateOrderStatusLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setOrdersStatusesData(orders);
  }, [orders]);

  useEffect(() => {
    setOrderStatusDataIndex(orderIndex);
  }, [orderIndex]);

  const returnModalAction = useCallback(() => {
    function execute() {
      onModalResponse();
    }

    execute();
  });

  return (
    <Modal
      isVisible={visible}
      width={px2vw(500)}
      height={px2vw(200)}
      padding={px2vw(10)}
    >
      {ordersStatusesData[orderStatusDataIndex] && (
        <ConfirmPreparingSchedulingOrderModal>
          <ConfirmPreparingSchedulingOrderText>
            {`Tem certeza que deseja iniciar o preparo do pedido #${ordersStatusesData[orderStatusDataIndex].order.id}, que está agendado para ${ordersStatusesData[orderStatusDataIndex].order.scheduling_start_at}?`}
          </ConfirmPreparingSchedulingOrderText>
          <ModalActions>
            <ModalSecundaryButton
              marginRight={px2vw(20)}
              margtop={px2vw(25)}
              width={px2vw(120)}
              type="button"
              onClick={() => {
                setVisible(false);
                returnModalAction();
              }}
              disabled={updateOrderStatusLoading}
            >
              <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
            </ModalSecundaryButton>
            <ModalPrimaryButton
              margleft={px2vw(20)}
              margtop={px2vw(25)}
              width={px2vw(120)}
              type="button"
              onClick={() => {
                dispatch(
                  updateOrderStatusRequest(
                    ordersStatusesData[orderStatusDataIndex],
                    'preparing',
                  )
                );
              }}
              disabled={updateOrderStatusLoading}
            >
              {updateOrderStatusLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              ) : (
                <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
              )}
            </ModalPrimaryButton>
          </ModalActions>
        </ConfirmPreparingSchedulingOrderModal>
      )}
    </Modal>
  );
}
